import React, { useState } from "react";
import {
  SearchInputBox,
  SearchInput,
  InputIcone,
  IconeI,
  SearchIcones
} from "../styles/MainLayout";
import { CiSearch } from "react-icons/ci";
import { connect } from "react-redux";
import HeaderIcons from "./headerIcons";
import { setSideBar } from '../../redux/Functions/actions';
import { useLocation } from "react-router-dom";



const SearchInputHeader = (props) => {
  const location = useLocation();

  return (
    <>
      <SearchIcones>
        <SearchInputBox>
          <InputIcone collapse={props?.sidebar}>
            {props?.view == true 
            || location.pathname == "/addsalon"
             ?
            ""
            :
            <>
            <SearchInput
              placeholder="Search for ..."
              type="search"
              onChange={(e) => props?.filterByContent(e.target.value)}
              />
            <IconeI>
              <CiSearch size={18} />
            </IconeI>
              </>
            }
          </InputIcone>
        </SearchInputBox>
        <HeaderIcons />
      </SearchIcones>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    // selected_lang: state.data.selected_lang,
    // logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    // dataFilter: state?.data?.dataFilter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchInputHeader);
// export default SearchInputHeader;


