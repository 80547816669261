import React from "react";
import { useState } from "react";
import { TableStyle, PaginationStyle } from "../styles/MainLayout"
import { CardStyle } from "../styles/MainLayoutResponsive"
import ServiceModal from "../Modal/ServiceModal"
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import SearchInputHeader from "./SearchInputHeader";
import axios from "../../axios"
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Services = (props) => {
    const {sid} = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState()
    const [serviceData , setServiceData] = useState()
    const [subservices , setSubServices] = useState([])
    const [dataToModal, setDataToModal] = useState(null);



   // PAGINATION
   const [currentPage, setCurrentPage] = useState(0);
   const startIndex = currentPage * 8;
   const endIndex = startIndex + 8;
   const numPages = Math.ceil(subservices.length/ 8)

   const data = subservices?.slice(startIndex, endIndex);
   const getServices = () =>{
    axios.get(`/api/store/salon_services/all`).then(
        data => {
            console.log("serviset",data.data)
            setSubServices(data.data)
        }
    ).catch(
        err =>{
            console.log(err)
        }
    )
}
useEffect(()=> {
    getServices();
},[])
console.log("data", data)

   const handlePageChange = ({ selected }) => {
       setCurrentPage(selected);
   };
   //PAGINATION


    const handleModalAdd = () => {
        setModalType("add")
        setIsOpen(true)
    }
    const handleModalEdit = (data) => {
        setModalType("edit")
        setIsOpen(true)
        setDataToModal(data)
    }
    const handleModalView = (data) => {
        setModalType("view")
        setIsOpen(true)
        setServiceData(data)
    }
    const handleModalClose = () => {
        setIsOpen(false)
    }

   
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener('resize', () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader/>

                {isOpen === true ?
                    <ServiceModal 
                    getNewService={() => getServices()}
                    isOpen={isOpen} 
                    type={modalType} 
                    handleModalClose={handleModalClose} 
                    dataToModal={dataToModal}
                    />
                    :
                    ""
                }
                <TableStyle collapse={props?.sidebar}>
                    <table className="table table-hover">
                        <tr>
                            <th className="col-1">ID</th>
                            <th className="col-4">Service</th>
                            <th className="col-3">Time</th>
                            <th className="col-3">Is Favourite</th>
                            <th className="col-3">Actions</th>
                        </tr>
                        {data.map(el => {
                            return (
                                <>
                                    <tr>
                                        <th className="col-1">{el.id}</th>
                                        <td className="col-4">{el.name_al}</td>
                                        <td className="col-3">{el.duration}</td>
                                        <td className="col-3">{el.is_fav == true ? "Yes" : "No"}</td>
                                        <td className="col-3">
                                            <div className="d-flex gap-3">
                                                <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={()=> handleModalEdit(el)} />
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </table>
                </TableStyle>


                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                    />
                </PaginationStyle>
            </>
        )
    }
    else {
        return (
            <>
            <SearchInputHeader/>
                {isOpen === true ?
                    <ServiceModal isOpen={isOpen} type={modalType} handleModalClose={handleModalClose} data={serviceData} />
                    :
                    ""
                }
                <div className="container" style={{ position:'fixed', background: "white",
    opacity: "0.1"}}/>
                    {data.map(el => {
                        return (
                            <>

                                <CardStyle>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-8">
                                                <ul class="list-group gap-2 pt-2 pb-2 ps-3">
                                                <li class="list-group">ID : {el.id} </li>
                                                    <li class="list-group">Service : {el.name_al} </li>
                                                    <li class="list-group">Time :{el.duration} </li>
                                                    <li class="list-group">Is Favourite: {el.is_fav == true ? "Yes" : "No"}</li>
                                                </ul>
                                            </div>
                                            <div className="col-3 pt-2">
                                                <div className="d-flex gap-3 pt-2">
                                                    <i class=""></i>
                                                    <i class=""></i>
                                                    <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={()=> handleModalEdit(el)}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardStyle>
                            </>
                        )
                    })}

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      selected_lang: state.data.selected_lang,
      logged_in: state.data.logged_in,
      sidebar: state?.data?.sidebar
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setSideBar: (data) => dispatch(setSideBar(data))
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(Services);
// export default Services;