import React, { useEffect } from "react";
import { useState } from "react";
import { PaginationStyle, TableStyle, TextForNothing } from "../styles/MainLayout"
import { CardStyle, AddButtonMobile } from "../styles/MainLayoutResponsive"
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import ReactPaginate from 'react-paginate';
import SearchInputHeader from "./SearchInputHeader";
import axios from "../../axios";

const Membership = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [getmembershipData, setGetMembershipData] = useState()
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    //AXIOS -(get)
    const getMembership = () => {
        axios
            .get("/api/store/subscriptions/all")
            .then((response) => {
                console.log("olaLabels", response.data);
                setGetMembershipData(response.data)
                setFilteredData(response.data)

            }).catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getMembership();
    }, [])

    console.log("get", getmembershipData)

    // FILTER FUNCTION
    const [filteredData, setFilteredData] = useState();
    const filterByContent = async (filterValue) => {
        if (filterValue) {
            const filteredArray = getmembershipData.filter((el) =>
                el?.name?.toLowerCase().includes(filterValue?.toLowerCase())
            );
            setFilteredData(filteredArray);
        } else if (filterValue == "") {
            setFilteredData(getmembershipData);
        }
    };

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(0);
    const startIndex = currentPage * 4;
    const endIndex = startIndex + 4;
    const numPages = Math.ceil(filteredData?.length / 4)

    const data = filteredData?.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    //PAGINATION

    // FILTER FUNCTION

    const handleModalClose = () => {
        setIsOpen(false);
        setIsOpenDelete(false);

    }

    console.log("setopen", isOpen)


    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener('resize', () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
                <TableStyle collapse={props?.sidebar}>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                {/* <th scope="col-1">ID</th> */}
                                <th scope="col-3">Membership Name</th>
                                <th scope="col-6">Membership Description</th>
                                <th scope="col-6">Appointment Limit</th>
                                <th scope="col-6">Price</th>
                                <th scope="col-3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(el => {
                                return (
                                    <>
                                        <tr>
                                            {/* <th scope="row" className="col-1">{el.id}</th> */}
                                            <td className="col-4">{el.name}</td>
                                            <td className="col-6">{el.desc}</td>
                                            {el.appt_limit > 9999999 ?
                                                <td className="col-6"><h3>∞</h3> </td>
                                                :
                                                <td className="col-6"> {el?.appt_limit}</td>
                                            }
                                            <td className="col-6">{el.price} €</td>
                                            <td className="col-3">
                                                {/* <div className="d-flex gap-3">
                                                    <i className="fa-regular fa-pen-to-square"onClick={()=> handleModalEdit(el)} />
                                                    <i class="fa-regular fa-trash-can" onClick={() => handleModalDelete(el)} />

                                                </div> */}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </TableStyle>
                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                    />
                </PaginationStyle>
                {/* <AddButton>
                <i type={"button"} className="fa-solid fa-circle-plus fa-xl" style={{color: "#FF5758"}} onClick={()=> handleModalAdd()}></i>
                </AddButton> */}
            </>

        )
    }
    //RESPONSIVE VIEW
    else
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
                <div className="container">
                    {data?.map(el => {
                        return (
                            <>
                                <CardStyle>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <ul className="list-group gap-2 pt-2 pb-2 ">
                                                    <li class="list-group text-center">
                                                        <strong>Membership Name</strong>
                                                        {el.name}
                                                    </li>
                                                    <hr className="m-0 w-100 " />
                                                    <li class="list-group text-center">
                                                        <strong>Membership Description</strong>
                                                        <span className="text-jusify col-11 mx-auto">
                                                            {el.desc}
                                                        </span>
                                                    </li>
                                                    <li class="list-group text-center">
                                                        <strong>Appointment Limit</strong>
                                                        {el.appt_limit}
                                                    </li>
                                                    <li class="list-group text-center">
                                                        <strong>Price</strong>
                                                        {el.price}
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <div className="col-3 pt-2">
                                                <div className="d-flex gap-3">
                                                    <i className="fa-regular fa-pen-to-square  pt-2" onClick={() => handleModalEdit(el)}></i>
                                                    <i class="fa-regular fa-trash-can pt-2" onClick={() => handleModalDelete(el)} />
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardStyle>
                            </>
                        )
                    })}
                    {data === undefined ?
                        <TextForNothing>
                            <h6 className="mt-3">
                                "There is no data available"
                            </h6>
                        </TextForNothing>
                        :
                        <PaginationStyle collapse={props?.sidebar}>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={numPages}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination justify-content-center"}
                                activeClassName={"active"}
                            // currentPage={data.length > 7 ? true : false}
                            />
                        </PaginationStyle>
                    }
                    <AddButtonMobile>
                        {/* <i type={"button"} className="fa-solid fa-circle-plus d-flex flex-row-reverse fa-xl" style={{ color: "#FF5758" }}></i> */}
                    </AddButtonMobile>
                </div>
            </>
        )
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        sidebar: state?.data?.sidebar
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Membership);
// export default Membership;