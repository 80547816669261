import React from 'react'
import RegisterForm from '../../components/form/RegisterForm/RegisterForm'

const Register = () => {
  return (
  <>
  <RegisterForm/>
  </>
  )
}

export default Register
