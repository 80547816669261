import React, { useEffect, useState } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import { UsersForm, FormControl,LabelProfile,DivForLabelAndInput,
     ModalAddButtonUsers, Label,InputProfileForm, Input, ModalAddButton,
      FormTitle, SelectOption, SelectOptionBox,NameFirst, ModalStyle, ModalCloseButton,CloseButton,
       NameSurname,Surname, ModalDarkBackGround, EditFormControl, UserEditForm, ModalEditButton, DivForLabelAndInputName, NameSurnameFirst, CloseButtonMobile } from "../../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile } from "../../styles/MainLayoutResponsive"
import axios from '../../../axios';
import { toast } from 'react-toastify';
import sign from "jwt-encode"
import { connect } from "react-redux";
import { setUserData } from "../../../redux/Functions/actions";

const Modal = (props) => {
    const [roles, setRoles] = useState([])
    const [subs, setSubs] = useState([])
    const [salons, setSalons] = useState([])
    const [address, setAddress] = useState([])
    const secretKey = process.env.REACT_APP_SECRET_KEY
    console.log('propssss', props)

    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
const getAddress = ()=>{
    axios.get(`/api/store/salon_address/all_addresses/${props?.user_data?.salon_id}`).then(
        data => {
            setAddress(data.data)
        }
        ).catch(err =>{
            console.log(err)
        })
}
console.log("adresat",address.filter(el => el.salon_id == props?.user_data?.salon_id))

    const getSubs = () => {
        axios.get('/api/store/subscriptions/all').then(
            data => {
                console.log(data)
                setSubs(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    const getData = () => {
        axios.get('/api/store/user_roles/all').then(data => {
            console.log('allusers get req', data.data);
            setRoles(data.data)
        }).catch(err => console.log(err));
    }
    const getSalons = () => {
        axios.get('/api/store/salon/all').then(data => {
            console.log('allusers get req', data.data);
            setSalons(data.data)
        }).catch(err => console.log(err));
    }
    const addUser = (e) => {
        let passEnc = sign(e.target?.password?.value, secretKey)
        console.log('eventGashji', e)
        e.preventDefault();
        let body = {
            name: e?.target?.first_name?.value,
            surname: e?.target?.last_name?.value,
            username: e?.target?.username?.value,
            email: e?.target?.email?.value,
            password: passEnc,
            salon_id: props?.user_data?.salon_id,
            gender: e?.target?.gender?.value,
            phone_number: e?.target?.phone_number?.value,
            role_id: 4,
            default_language: e?.target?.default_language.value,
            created_by: props?.user_data?.user_id,
            salon_address_id: Number(e.target.address_id.value)
        }
        console.log('postdata',body)
        axios.post("/api/store/users/", body)
            .then(data => {
                console.log("users added" + data.data)
                props?.handleModalClose();
                props?.getNewUsers();
                toast.success("User Added Sucesfully!")
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
    }

    useEffect(() => {
        getSubs()
        getData()
        getSalons()
        getAddress()
    }, [])
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                        <CloseButton>
                            <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            </CloseButton>
                            {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add Staff</FormTitle>
                                   <hr/>
                                    <UsersForm onSubmit={addUser}>
                                        <FormControl>
                                            <NameSurnameFirst className="row">
                                                <NameFirst className="col-md-6">
                                                <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm required id="first_name" name="first_name" placeholder="Your first name" type="text" />
                                                </DivForLabelAndInput>
                                                </NameFirst>

                                                <Surname className="col-md-6">
                                                <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Last Name</LabelProfile>
                                            <InputProfileForm required id="last_name" name="last_name" placeholder="Your last name" type="text" />
                                            </DivForLabelAndInput>
                                         
                                                </Surname>
                                            </NameSurnameFirst>
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Username</LabelProfile>
                                            <InputProfileForm required id="username" name="username" placeholder="Username" type="text" />
                                            </DivForLabelAndInput>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Phone Number</LabelProfile>
                                            <InputProfileForm required id="phone_number" name="phone_number" placeholder="Your phone number" type="number" />
                                            </DivForLabelAndInput>
                                         
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm required id="email" name="email" placeholder="Your email" type="email" />
                                            </DivForLabelAndInput>
                                         
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile>Password</LabelProfile>
                                            <InputProfileForm required id="password" name="password" placeholder="*****" type="password" />
                                            </DivForLabelAndInput> 
                                         
                                            <SelectOptionBox className="row m-0">
                                            <DivForLabelAndInput className="col-md-6">
                                                <SelectOption required id="address_id" name="address_id">
                                                    <option>Select address</option>
                                                    {address.map(el => {
                                                        return (
                                                            <>
                                                                <option value={el?.id}>{el?.name_al}</option>
                                                            </>
                                                        )
                                                    })}
                                                </SelectOption>
                                                    </DivForLabelAndInput>
                                                <DivForLabelAndInput className="col-md-6">
                                                <SelectOption required id="gender" name="gender">
                                                    <option>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </SelectOption>
                                                </DivForLabelAndInput>

                                                <DivForLabelAndInput className="col-md-6">
                                                <SelectOption required id="default_language" name="default_language">
                                                    <option>Select Language</option>
                                                    <option value={1}>Shqip</option>
                                                    <option value={2}>English</option>
                                                </SelectOption>
                                                </DivForLabelAndInput>
                                            </SelectOptionBox>
                                        <div className="d-flex justify-content-end">
                                        <ModalAddButtonUsers type="submit" >Add</ModalAddButtonUsers>
                                        </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                props?.type === "edit" || props?.type === "view" ?
                                <>
                                        <FormTitle className="d-flex justify-content-center mt-3">View User</FormTitle>
                                        <hr/>
                                <UserEditForm>
                                        <EditFormControl>
                                        <NameSurname>

                                                <NameFirst>
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.name} />
                                            </DivForLabelAndInput>
                                            </NameFirst>

                                                <Surname>
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Last Name </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.surname} />
                                            </DivForLabelAndInput>
                                            </Surname>

                                        </NameSurname>

                                        <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Phone Number </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.phone_number} />
                                        </DivForLabelAndInput>

                                        <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.email} />
                                        </DivForLabelAndInput>
{/* 
                                        <DivForLabelAndInput className="forDisable">
                                            <LabelProfile>Password</LabelProfile>
                                            <InputProfileForm placeholder="...." type="password" disabled={props?.type === "view"} defaultValue={props?.data?.password} />
                                        </DivForLabelAndInput> */}



                                            <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="gender" name="gender">
                                                    <option>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </SelectOption>
                                              </DivForLabelAndInput>

                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="default_language" name="default_language">
                                                    <option>Select Language</option>
                                                    <option value={1}>Shqip</option>
                                                    <option value={2}>English</option>
                                                </SelectOption>
                                        </DivForLabelAndInput>
                                            </SelectOptionBox>



                                            {/* <Label>Created_at </Label>
                                                                 <Input placeholder="...." type= "text"/> */}
                                            {props?.type === "edit" ?
                                                <ModalEditButton type={"button"}>Save</ModalEditButton>
                                                :
                                                ""
                                            }
                                        </EditFormControl>
                                    </UserEditForm>
                            </>
                                    :
                                    ""
                            }

                        </ModalStyle>
                    </ModalPortal>
                    </>
                    )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />

                    <ModalStyleMobile>
<CloseButtonMobile>

                        <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
</CloseButtonMobile>
                        {props?.type === "add" ?
                                <>
                                    <FormTitle className="d-flex justify-content-center mt-3">Add Staff</FormTitle>
                                    <hr/>
                                    <UsersForm onSubmit={addUser}>
                                        <FormControl>
                                          <NameSurname >
                                                <NameFirst >
                                                <DivForLabelAndInputName className="forDisable">
                                               <LabelProfile >First Name</LabelProfile>
                                               <InputProfileForm required id="first_name" name="first_name" placeholder="Your first name" type="text" />
                                               </DivForLabelAndInputName>
                                                </NameFirst>

                                                <Surname >
                                                <DivForLabelAndInputName className="forDisable">
                                               <LabelProfile >Last Name</LabelProfile>
                                               <InputProfileForm required id="last_name" name="last_name" placeholder="Your last name" type="text" />
                                                </DivForLabelAndInputName>
                                                </Surname>
                                            </NameSurname>
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Username</LabelProfile>
                                            <InputProfileForm required id="username" name="username" placeholder="Username" type="text" />
                                            </DivForLabelAndInput>
                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile >Phone Number</LabelProfile>
                                            <InputProfileForm required id="phone_number" name="phone_number" placeholder="Your phone number" type="number" />
                                            </DivForLabelAndInput>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm required id="email" name="email" placeholder="Your email" type="email" />
                                            </DivForLabelAndInput>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile>Password</LabelProfile>
                                            <InputProfileForm required id="password" name="password" placeholder="*****" type="password" />
                                            </DivForLabelAndInput>
                                                      <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="gender" name="gender">
                                                    <option>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </SelectOption>
                                              </DivForLabelAndInput>
                                              <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="address_id" name="address_id">
                                                    <option>Select address</option>
                                                    {address.map(el => {
                                                        return (
                                                            <>
                                                                <option value={el?.id}>{el?.name_al}</option>
                                                            </>
                                                        )
                                                    })}
                                                </SelectOption>
                                                    </DivForLabelAndInput>
                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="default_language" name="default_language">
                                                    <option>Select Language</option>
                                                    <option value={1}>Shqip</option>
                                                    <option value={2}>English</option>
                                                </SelectOption>
                                        </DivForLabelAndInput>
                                            </SelectOptionBox>
                                            <div className="d-flex justify-content-end">
                                        <ModalAddButtonUsers type="submit" >Add</ModalAddButtonUsers>
                                        </div>
                                        </FormControl>
                                    </UsersForm>
                                </>
                                :
                                props?.type === "edit" || props?.type === "view" ?
                                <>
                                        <FormTitle className="d-flex justify-content-center mt-3">View User</FormTitle>
                                <hr/>
                                    <UserEditForm>
                                        <EditFormControl>
                                        <NameSurname>

                                                <NameFirst>
                                            <DivForLabelAndInputName className="forDisable">
                                            <LabelProfile >First Name</LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.name} />
                                            </DivForLabelAndInputName>
                                            </NameFirst>

                                                <Surname>
                                            <DivForLabelAndInputName className="forDisable">
                                            <LabelProfile> Last Name </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.surname} />
                                            </DivForLabelAndInputName>
                                            </Surname>
                                            </NameSurname>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Phone Number </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.phone_number} />
                                            </DivForLabelAndInput>

                                            <DivForLabelAndInput className="forDisable">
                                            <LabelProfile> Email </LabelProfile>
                                            <InputProfileForm placeholder="...." type="text" disabled={props?.type === "view"} defaultValue={props?.data?.email} />
                                            </DivForLabelAndInput>
                                        
                                       <SelectOptionBox className="gap-3 d-flex mt-2">
                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="gender" name="gender">
                                                    <option>Select Gender</option>
                                                    <option value={1}>Male</option>
                                                    <option value={2}>Female</option>
                                                </SelectOption>
                                              </DivForLabelAndInput>

                                        <DivForLabelAndInput className="forDisable">
                                                <SelectOption required id="default_language" name="default_language">
                                                    <option>Select Language</option>
                                                    <option value={1}>Shqip</option>
                                                    <option value={2}>English</option>
                                                </SelectOption>
                                        </DivForLabelAndInput>
                                            </SelectOptionBox>
                                            {props?.type === "edit" ?
                                                <ModalEditButton type={"button"}>Save</ModalEditButton>
                                                :
                                                ""
                                            }
                                        </EditFormControl>
                                    </UserEditForm>
                                </>

                                    :
                                    ""
                            }
                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserData: (data) => dispatch(setUserData(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
