import React, { useState } from 'react'
import { useNavigate } from "react-router";
import LogoTermini from "../../../assets/images/termini-beauty-logo.png"
import { ButtonR } from "../../../components/styles/MainLayout";
import Footer from '../../../components/LandingPage/Footer';
import "../../LandingPage/LandingPage.scss"
import { Buttons, FormLogin, InputEmail, InputPass, Loginbutton, Paragraf } from '../../styles/MainLayout';
import axios from '../../../axios';
import { toast } from 'react-toastify';
const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    const navigateToLogin = () => {
        navigate('/login');
    };
    const handlePasswordReset = (e) => {
        e.preventDefault();
        const body = {
            email: email,
        }
        axios
            .post("/api/auth/reset_password/generate-link", body)
            .then((response) => {
                // console.log('response', response)
                toast.success(response.data.message)
                navigate('/')
                // handleModalClose();
            })
            .catch((error) => {
                toast.error("Autentifikimi dështoi!");
                console.log('error:', error);
            });
    };
    return (
        <>
            <header className='headerLogin' style={{ background: 'white', width: '100%', top: '0', height: '100px' }}>
            </header>
            <div className=" loginLayout container" >
                <div className="logoTermini mt-5">
                    <img src={LogoTermini} alt="" />
                </div>
                <div className="backgroundImage mb-2">
                    <div className="mt-5 formLayout">
                        <div className="row">
                            <div className="col-md-6 rightText">
                                <h1 className='headingText'>Ndrysho Passwordin!</h1>
                            </div>
                            <div className="col-md-6">
                                <FormLogin className="mt-4">
                                    <InputEmail
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        // value={email}
                                        // defaultValue={email}
                                        
                                        type="email"
                                        placeholder="Email"
                                    ></InputEmail>
                                    <Buttons className="mt-3">
                                        <Loginbutton onClick={(e) => handlePasswordReset(e)}>NDRYSHO PASSWORDIN</Loginbutton>
                                        <ButtonR onClick={()=>navigateToLogin}>KYÇU</ButtonR>
                                    </Buttons>
                                    {/* <Paragraf>Keni harruar fjalëkalimin?</Paragraf> */}
                                </FormLogin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ResetPassword
