import React, { } from 'react'
import { useState } from 'react';
import ModalPortal from "../../portal/ModalPortal";
import { ModalDarkBackGround,FormTitle, DeleteModal, DeleteModalButton,ModalStyleDelete,
     ModalDeleteButton,ModalCancelButton, FormTitleResponsive } from "../styles/MainLayout";
import axios from "../../axios"
import { toast } from 'react-toastify';

const AppointmentDeleteModal = (props) => {
    //AXIOS (Delete)
    console.log("senet",props)
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    const handleDelete = (e) => {
        e.preventDefault()
      axios.put(`/api/store/appointments/cancel_appointment/${props?.dataToModal?.id}`)
        .then((response) => {
          console.log("olaLabels", response.data);
          toast.success("Appointment canceled sucessfully.")
          props?.handleModalClose();
          props?.getAppointmentsDelete();
        }).catch(err => {
          toast.error("Something went wrong")
          console.log(err)
        })
  };
    //AXIOS
    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal >
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <h2>Confirmation</h2>
                                <br/>
                                <FormTitle className="d-flex justify-content-center mt-3">Are you sure you want to cancel"<strong> {props?.dataToModal?.reserver_name}</strong>"? </FormTitle>
                                <p className='a'>Do you really want to cancel this appointment? This <br />process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-5'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Close</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Confirm</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                    </ModalPortal>
                </>
            )
        }
        else {
            return (
                <>
                <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                <ModalStyleDelete>
                            <DeleteModal className='mt-5'>
                                <h2>Confirmation</h2>
                                <br/>
                                <FormTitleResponsive className="d-flex justify-content-center mt-3">Are you sure you want to cancel "<strong> {props?.dataToModal?.reserver_name}</strong>"? </FormTitleResponsive>
                                <p className='a'>Do you really want to cancel this appoint <br/> ment? This process cannot be undone.</p>
                            </DeleteModal>
                                <DeleteModalButton className=' mt-4'>
                                    <ModalCancelButton onClick={() => props.handleModalClose()}>Close</ModalCancelButton>
                                    <ModalDeleteButton onClick={(e) => handleDelete(e)} >Confirm</ModalDeleteButton>
                                </DeleteModalButton>
                        </ModalStyleDelete>
                </>
            )
        }
    }
    else
        return null
}

export default AppointmentDeleteModal
