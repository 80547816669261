import ServicesTable from "../../components/form/servicesResponsiveTable";


const Services = () => {
    return(
        <>
        <ServicesTable/>
        </>
    )
}
export default Services;