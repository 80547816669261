import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Users from './pages/users/Users';
import Membership from './pages/membership/memberships';
import Services from './pages/services/services';
import Sidebar from './components/sidebar/sidebar';
import Salons from './pages/salons/Salons';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './pages/profile/profile';
import AddSalonView from './pages/salons/AddSalon/AddSalon';
import 'react-toastify/dist/ReactToastify.css';
import EditSalonView from "./pages/salons/EditSalon/editSalon";
import NotFound from './components/404NotFound/404';
import Appointments from "./pages/appointments/appointments"
import { connect } from "react-redux";
import { setLoggedIn, setExpiredToken, setUserToken, setUserData } from './redux/Functions/actions';
import { useEffect } from 'react';
import MultistepForm from './pages/salons/MultiStepAddSalon/MultiStepForm';
import 'react-image-lightbox/style.css';
import { useState } from 'react';
import LandingPage from './components/LandingPage/LandingPage';
import ResetPassword from './components/form/LoginForm/ResetPassword';
import ChangePassword from './components/form/LoginForm/ChangePassword';
import TermsAndConditions from './pages/termsAndConditions/termsAndConditions';
import Clients from './pages/clients/clients';




function App(props) {
  const [view, setView] = useState(0)

  useEffect(() => {
    const currentDate = new Date();
    const expireDate = new Date(props?.expire_token);
    // console.log("expireToken", props?.expire_token)
    if (props?.logged_in && expireDate && expireDate.getDate() !== currentDate.getDate()) {
      alert("Sesioni juaj ka skaduar, ju lutem kyquni përsëri!");
      props.setExpiredToken(null);
      props.setLoggedIn(false);
      console.log('appprops', props)
      props.setUserToken(null);
      props.setUserData([]);
    }
  }, []);
  let token = window.localStorage.getItem('token')

  // Rest of your component code...



  // THE VALIDATION OF THE ROUTE NEEDS TO BE DONE UNDER ONE CONDITION, HERE YOU NEED TO CHANGE ONE OF THE ROUTES PATH (CALENDAR !) TO PATH="/" IN ORDER TO NOT NAVIGATE FROM ONE PAGE TO ANOTHER BUT TO ONLY RENDER DIFERRENT ROUTES!
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '9999' }} />
      <Router>
        {props?.logged_in === true && <Sidebar salonId={props?.user_data?.salon_id} appView={view} />}

        {/* {props?.user_data?.role === 4 && } */}
        <Routes>

          {props?.logged_in === false ? (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<LandingPage />} />
              <Route exact path="/login" element={<Login />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route exact path="/resetPassword" element={<ResetPassword />} />
              <Route exact path="/changePassword/:token" element={<ChangePassword />} />
              <Route exact path="/register" element={<Register />} />
            </>)
            :

            props?.logged_in === false && <Route path="*" element={<Login />} />
          }

          {
            props?.logged_in === true && token != null ?

              (
                <>
                  {/* <Route exact path="/" element={<Appointments />} /> */}
                  {props?.user_data?.role === 4 ?

                    <>
                      {/* {console.log(props?.user_data?.role, props?.logged_in)} */}
                      <Route exact path="/" element={<Appointments salonId={props?.user_data?.salon_id} view={view} setView={(e) => setView(e)} />} />
                      <Route path="/profile/:pid/:uuid" element={<Profile />} />
                      <Route path="*" element={<NotFound />} />
                    </>
                    :
                    <>
                      <Route path="/" element={<Users />} />
                      <Route exact path="/appointments" element={<Appointments salonId={props?.user_data?.salon_id} view={view} setView={(e) => setView(e)} />} />
                      <Route exact path="/appointments/pending" element={<Appointments salonId={props?.user_data?.salon_id} view={view} setView={(e) => setView(e)} />} />
                      <Route exact path="/profile/:pid/:uuid" element={<Profile />} />
                      <Route exact path="/addsalon" element={<MultistepForm />} />
                      <Route exact path="/editsalon/:id" element={< EditSalonView />} />
                      <Route path="/membership" element={<Membership />} />
                      <Route path="/services" element={<Services />} />
                      <Route path="/salon" element={<Salons />} />
                      <Route path="/clients" element={<Clients />} />
                    </>
                  }
                </>)
              :
              <>
                <Route path={"/addsalon" && "/profile" && "/membership" && "/services" && "/salons" && "/language" && "/editsalon/:id"} element={<Login />} />
                <Route path="*" element={<Login />} />
              </>


          }
        </Routes>
      </Router>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    user_data: state?.data?.user_data,
    expire_token: state?.data?.expire_token
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default SearchInputHeader;
// export default App;
