import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import axios from '../../../../axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../EditSalon/editSalon.scss';
const MapContainer = (props) => {
console.log("map props" , props)
    const [markerPosition, setMarkerPosition] = useState(null);
  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW


    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        const newPosition = { lat, lng };
        setMarkerPosition(newPosition);
    };

    //AXIOS
    const addLocation = (e) => {
        // e.preventDefault()
        if(props?.city == "" ||props?.street == "" ||props?.description == "" ||markerPosition == null ){
            toast.warning("Please fill all the information")
        }
        else{

            let data = {
                salon_id: props?.salon_id,
                name_al:props?.city,
                name_en:props?.city,
                street_address_al:props?.street,
                street_address_en:props?.street,
                latitude: markerPosition?.lat,
                longitude: markerPosition?.lng,
                desc_al: props?.description,
                desc_en:props?.description,
                created_by: 5
            }
        axios.post("/api/store/salon_address", data)
        .then(data => {
            console.log("data salon address", data)

            const formData = new FormData();
            formData.append('salon_id', data?.data?.address_data?.id);
            formData.append('img_url', props.image);
            formData.append('img_alt', props.imageTitle);
      
              axios.post(`/api/store/salon_images`, formData)
                .then(data => {
                  console.log("image shkoj", data.data)
                }
                ).catch(err => {
                  console.log(err)
                  toast.error("image Didnt upload")
                })
            
                toast.success("Location added successfully !")
                props?.nextStep()
                props?.setAddressId(data?.data?.address_data?.id)
            }).catch(err => {
                toast.error("Something went wrong")
                console.log(err)
            })
        }
        }
        
        //AXIOS
        
        return (
            <>
            {/* {markerPosition == null ? "" :
                <form onSubmit={addLocation}>
                    <button className='btn btn-md btn-success' type='submit'>Save</button>
                </form>
            } */}
             <div className="col">
            <Map
                // className="mapEditSalon"
                containerStyle={{ marginTop: '5px',height:'230px',width: viewportSize > 800 ? '550px' : '280px',position:'relative', border:'2px solid #b4dfc0'}}
                google={props.google}
                zoom={14}
                initialCenter={{
                    lat: 42.66299247342869,
                    lng: 21.162134198227506
                }}
                //   ref={mapRef}
                streetViewControl={false}
                onClick={handleMapClick}
                >
                {markerPosition && <Marker position={markerPosition} />}
         
                {/* <div style={{width:'300px' , height:'10vh'}}>
                <PlacesAutocomplete
                onChange={(address) => handlePlaceSelect(address)}
                />
            </div> */}
            </Map>
            </div>
            <button className='Navbutton' onClick={()=> addLocation()}>Next</button>
        </>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAx2mpUulK8e5rn9PB09xmSDDccbCoVMps'
})(MapContainer);
