import React, { useState } from "react";
import {
  FormLogin,
  InputEmail,
  InputPass,
  Buttons,
  Paragraf,
  Loginbutton,
  Labels,
  RegisterbuttonAtLogin, EyePass, ButtonR
} from "../../styles/MainLayout";
import axios from "../../../axios";

import { connect } from "react-redux";
import { setLoggedIn, setUserData, setUserToken, setExpiredToken } from "../../../redux/Functions/actions";
import Aos from "aos";
import { useNavigate } from "react-router";
import sign from "jwt-encode"
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
const LoginForm = (props) => {
  console.log('login dajko props', props)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  // const sign = require('jwt-encode');
  const secretKey = process.env.REACT_APP_SECRET_KEY

  console.log('env secret key ', process.env.REACT_APP_SECRET_KEY)
  let passEnc = sign(password, secretKey)

  const body = { email: email, password: passEnc };
  // console.log("dataLogin", body);
  const navigate = useNavigate();

  const navigateToRegister = () => {
    // 👇️ navigate to /contacts
    navigate('/register');
  };

  const handleLogin = (e) => {
    console.log("HEj")
    e.preventDefault();
    axios
      .post("/api/auth/login", body)
      .then((response) => {
        if (response.status === 200) {
          window.localStorage.setItem('token', response?.data?.token);
          const secretKey = process.env.REACT_APP_SECRET_KEY
          const decodeToken = jwtDecode(response?.data?.token, secretKey)
          if (decodeToken.role === 2 || decodeToken.role === 4) {
            window.localStorage.setItem('token', response?.data?.token)
            props?.setUserToken(response.data.token);
            props?.setExpiredToken(decodeToken?.logout_timer);
            props?.setUserData(
              {
                role: decodeToken?.role,
                uuid: decodeToken?.uuid,
                user_id: decodeToken?.user_id,
                salon_id: decodeToken?.salon_id,
                auth: decodeToken?.auth,
                name: decodeToken?.name,
                surname: decodeToken?.surname,
                gender: decodeToken?.gender,
                language: decodeToken.language,
                phoneNumber: decodeToken.phoneNumber
              }

            );
            toast.success("You are logged in");
            props?.setLoggedIn(true)
            if (decodeToken.role == 2) {
              navigate('/salon');
            } else {
              navigate('/')
            }

            // console.log("decodeTokendecodeToken",decodeToken)
            // navigate('/appointments');
          }
          else if (decodeToken.role !== 2 || decodeToken.role !== 4) {
            toast.warning("You do not have access to this site!")
          }

          console.log("decodeToken", decodeToken)

        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        console.log('error login:', err.response
          .data.error);
      });
  };


  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  return (
    <>
      <FormLogin onSubmit={(e) => handleLogin(e)} className="mt-4">
        <InputEmail
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
          placeholder="Email"
        ></InputEmail>
        <InputPass
          required
          type={passwordType}
          placeholder="Fjalëkalimi"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        >
        </InputPass>
        {/* {passwordType === "password" ? <i class="fa fa-eye-slash passEye" onClick={togglePassword}></i> : <i class="fa fa-eye passEye" onClick={togglePassword}></i>} */}
        <Buttons className="mt-3">
          <Loginbutton type="submit">KYÇU</Loginbutton>
          <ButtonR onClick={navigateToRegister}>HAP LLOGARI</ButtonR>
          {/* <RegisterbuttonAtLogin onClick={navigateToRegister} ></RegisterbuttonAtLogin> */}
        </Buttons>
        <Paragraf onClick={() => navigate('/resetPassword')}>Keni harruar fjalëkalimin?</Paragraf>
      </FormLogin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
