import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { BsPen } from "react-icons/bs"
import { Salon, DeleteButtonSalon, EditButtonSalon, InputProfileForm, LabelProfile, DivForLabelAndInput, SelectOption } from "../../../components/styles/MainLayout"
import Aos from "aos";
import axios from "../../../axios";
import { toast } from "react-toastify";
import Map from "./EditMap/editMap";
import AddLocationModal from "../../../components/Modal/salonModals/addLocation"
import "./editSalon.scss"
import iconLocation from "../../../assets/images/8666640-64.png"
import { setSideBar, setUserData } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import SearchInputHeader from "../../../components/form/SearchInputHeader";
import Lightbox from 'react-image-lightbox';
import SalonServiceDeleteModal from "./SalonServiceDeleteModal";


const EditSalonView = (props) => {
  const [editTrigger, setEditTrigger] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showName, setShowName] = useState(false);
  const [showdesc, setShowdesc] = useState(false);
  const [showServices, setShowServices] = useState(true)
  const [salonLocations, setSalonLocations] = useState([])
  const [imageUrl, setImageUrl] = useState()
  const [locationValEn, setLocationValEn] = useState(salonLocations?.street_address_en)
  const [editName, setEditName] = useState(salonLocations?.street_address_al)
  const [images, setImages] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [addressId, setAddressId] = useState()
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgDelete, setImgDelete] = useState(false)
  const [handleImgIndex, setHandleImgIndex] = useState()
  const [services, setServices] = useState()
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [showSubServices, setSubShowServices] = useState(false)
  const [activeServiceIndex, setActiveServiceIndex] = useState(null);
  const [isFav, setIsFav] = useState(null)
  const [imageTitle, setImageTitle] = useState("")
  const [salonServiceId, setSalonServiceId] = useState([])
  const [dataToModal, setDataToModal] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [subServices, setSubservices] = useState([])
  const [staff, setStaff] = useState([])
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [allCities, setAllCities] = useState([])
  const [timeValues, setTimeValues] = useState({});
  const [price, setPrice] = useState(null);
  const [name_en, setName_en] = useState()




  const staticName = salonLocations?.name_al
  // const [lastID , setLastId] = useState()
  const { id } = useParams();
  Aos.init();
  const serviceID = salonServiceId.filter(elem => elem.salon_address_id == id).map(el => el?.sub_sub_service_id)
  console.log("salonServiceId", staff)
  //API 
  // const SalonImages = () => {
  //   axios.get(`/api/store/salon_images/salon_address/${id}`).then(
  //     data => {
  //       console.log(data.data)
  //       setImages(data.data)
  //     }
  //   )
  // }
  const getSalonAddressService = () => {
    axios.get(`/api/store/salon_services/all`).then(
      response => {
        console.log("salon address single", response)
        setSalonServiceId(response.data)
      }
    ).catch(err => {
      console.log(err)
    })
  }

  const getCities = () => {
    axios.get('/api/cities/all/').then(
      data => {
        console.log("ola", data.data)
        setAllCities(data.data)
      }
    ).catch(err => {
      console.log(err)
    })
  }

  const DeleteSalon = (id) => {
    axios.delete(`/api/store/salon_address/${id}`).then(
      (res) => {
        toast.success("Address Deleted");
      }
    ).catch(
      (err) => {
        console.log(err)
        toast.success("Something went wrong, try again later");
      }
    )
  }
  const [value, setValue] = useState("")
  const duration = Math.floor(value / 60);
  const remainingDuration = value % 60;
  const formatDuration = String(duration).padStart(2, '0');
  const formattedMinutes = String(remainingDuration).padStart(2, '0');
  let time = `${formatDuration}:${formattedMinutes}:00`

  const addSalonService = (subID, sub_subID, name_al, e) => {
    e.preventDefault()
    console.log('time', time)
    if (time == "NaN:NaN:00" || time == "00:00:00") {
      toast.error("Ju lutem shenoni kohezgjatjen e shërbimit!")
    } else {
      let body = {
        salon_address_id: id,
        service_id: selectedTabs[0],
        sub_service_id: subID,
        sub_sub_service_id: sub_subID,
        salon_id: props?.user_data?.salon_id,
        name_al: name_al,
        name_en: name_en,
        if_fav: isFav,
        duration: time,
      }
      // console.log("ADD SERVICE", body)
      axios.post("/api/store/salon_services", body)
        .then(data => {
          console.log(data.data)
          getSalon()
          getSalonAddressService()
          setValue("00:00:00")
          toast.success("Services have been added successfully")
        }
        ).catch(err => {
          console.log(err)
        })
    }

  }





  const EditSalonService = (subID, sub_subID, name_al, e, ids, is_fav) => {
    e.preventDefault()

    let body = {
      id: ids,
      salon_address_id: id,
      service_id: selectedTabs[0],
      sub_service_id: subID,
      sub_sub_service_id: sub_subID,
      salon_id: props?.user_data?.salon_id,
      name_al: name_al,
      name_en: name_en,
      price: price,
      is_fav: isFav != null ? `${isFav}` : `${is_fav}`,
      duration: time == "00:00:00" ? null : time,
    }
    console.log("edit service", body)
    axios.put("/api/store/salon_services/update", body).then(
      toast.success("Services have been updated successfully"),
      setEditingIndex(null)
    ).catch(err => {
      console.log(err)
    })
  }
  const EditSalon = (e) => {
    e.preventDefault();
    let data = {
      id: id,
      name_al: e.target.name_al.value,
      name_en: e.target.elements.name_al.value,
      desc_al: e.target.elements.desc_al.value,
      desc_en: e.target.elements.desc_en.value,
      created_by: props?.user_data?.id
    };
    axios.put(`/api/store/salon_address/update`, data)
      .then(response => {
        console.log("response", response)
        toast.success("Salon updated successfully");
        setShowdesc(false);
        setShowServices(true);


        const formData = new FormData();
        formData.append('salon_id', id);
        formData.append('img_url', imageUrl);
        formData.append('img_alt', imageTitle);
        axios.post(`/api/store/salon_images`, formData)
          .then(data => {
            console.log("image shkoj", data.data)
            setImageUrl(null)
            setSelectedImage(null)
            setImageTitle("")
            getSalon();
          }
          ).catch(err => {
            console.log(err)
            toast.error("image Didnt upload")
          })
      })
      .catch(err => {
        console.log(err);
        toast.error("Something went wrong, try again later")
      });
  };

  const getSalon = () => {
    axios.get(`/api/store/salon_address/single/${id}`)
      .then(data => {
        console.log("SALON ADRES", data.data)
        setSalonLocations(data.data)
        setImages(data?.data?.salon_imgs)
      }).catch(err => {
        console.log(err)
      })
  }
  const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const sortedWorkingHours = salonLocations?.salon_working_hours?.sort((a, b) => {
    return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
  });
  const DeleteImage = (imgID) => {
    axios.delete(`/api/store/salon_images/${imgID}`).then(
      toast.success("Image Deleted"),
    ).catch(err => {
      console.log(err)
      toast.error("image didnt get deleted")
    })
  }
  const getServices = () => {
    axios.get('/api/store/services/all').then(
      data => {
        console.log("serviset", data.data)
        setServices(data.data)
      }
    ).catch(
      err => {
        console.log(err)
      }
    )
  }

  console.log("serviste", services)

  const handleEditImage = (index) => {
    console.log("setEditTrigger", index)
    setEditTrigger(index)

  }
  const [changeImageDesc, setChangeImageDesc] = useState()
  const updateImageData = (el) => {
    alert("erdha")
    // formData.append('img_url', imageUrl);
    let imgBody = {
      id: el.id,
      salon_id: id,
      img_alt: changeImageDesc,
    };
    console.log("formData", imgBody)
    axios.put(`/api/store/salon_images`, imgBody).then(
      data => {
        console.log("image shkoj", data.data)
        // getSingleSalonAddress()
        // setImageUrl(null)
        // setSelectedImage(null)
        // setImageTitle("")
      }
    ).catch(err => {
      console.log(err)
      toast.error("image Didnt upload")
    })
    console.log("updateImageDate", el)

  }


  const handleDeleteSalonService = (sid) => {
    axios.delete(`/api/store/salon_services/${sid}`)
      .then((res) => {
        toast.success("u fshi")
        getSalon()
        getSalonAddressService()
      })
      .catch(err => toast.error(err))
  }
  //API
  //Functions 
  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)

  };
  const handleTabClick = (id) => {
    axios.get(`/api/store/sub_services/single/${id}`)
      .then(data => {
        console.log("sub service data", data.data)
        setSubservices(data.data)
      }).catch(err => {
        console.log(err)
      })
    if (selectedTabs.includes(id)) {
      setSelectedTabs(selectedTabs.filter((tabId) => tabId !== id));
      setActiveServiceIndex(null);
    } else {
      setSelectedTabs([id]);
      setActiveServiceIndex(id);
      setEditTrigger(null)

    }
    setSubShowServices(true);
  };
  const handleEditWorkingHours = (e, el) => {
    e.preventDefault()
    let body = {
      id: el.id,
      start_time: e.target.start_time.value != el?.start_time.slice(11, 19) ? `2023-05-27T${e.target.start_time.value}:00.000Z` : null,
      end_time: e.target.end_time.value != el?.end_time.slice(11, 19) ? `2023-05-27T${e.target.end_time.value}:00.000Z` : null,
      break_start: e.target.brake_start.value != el?.brake_start.slice(11, 19) ? `2023-05-27T${e.target.brake_start.value}:00.000Z` : null,
      break_end: e.target.brake_startbrake_end.value != el?.brake_end.slice(11, 19) ? `2023-05-27T${e.target.brake_startbrake_end.value}:00.000Z` : null,
      work_this_day: `${e.target.work_this_day.checked}`
    }
    axios.put("/api/store/salon_working_hours/update_agenda", body).then(data => {
      console.log(data.data)
      toast.success("Working Hours Updated!")
      setEditTrigger(null)
    }
    ).catch(err => {
      console.log(err)
      toast.error("something went wrong")
    })
    setEditTrigger(null)
  }
  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (e) {
      reader.readAsDataURL(e);
    }
  };
  const handleImage = (index) => {
    setHandleImgIndex(index);
    setImgDelete(true)
  };
  const handleLightboxOpen = () => {
    setIsOpenLightbox(true);
  };

  const handleLightboxClose = () => {
    setIsOpenLightbox(false);
  };

  const handleModalStaf = (data) => {
    setIsOpen(true);
    setModalType("staf");
    setDataToModal(data)
  };
  const handleAddModalStaf = (subid, responseid, responsename_al, value, serviceId, isFav) => {
    if (timeValues[responseid] === undefined || timeValues[responseid] === "") {
      toast.warning("Please select a time value");
    }
    else {
      setIsOpen(true);
      setModalType("add");
      setDataToModal([subid, responseid, responsename_al, value, serviceId, isFav])
    }
  };
  useEffect(() => {
    getSalon();
    getServices()
    getSalonAddressService();
    getCities()
    // SalonImages()
  }, [])

  return (
    <>
      <SearchInputHeader />

      {isOpen === true ? (
        <AddLocationModal
          isOpen={isOpen}
          handleModalClose={handleModalClose}
          data={dataToModal}
          getSalon={getSalon}
          getSalonAddressService={getSalonAddressService}
          type={modalType}
        />
      ) : (
        ""
      )}
      {isOpenDelete === true ? (
        <SalonServiceDeleteModal
          getSalonAddressService={() => getSalonAddressService()}
          getSalon={() => getSalon()}
          isOpen={isOpenDelete}
          type={modalType}
          dataToModal={dataToModal}
          handleModalClose={handleModalClose}
        />
      ) : (

        ""
      )}
      <div className="container bgSalon">
        <Salon collapse={props?.sidebar}>
          <p className="salonName" role="button" onClick={() => setShowName(true)}>{staticName} <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
          <form onSubmit={EditSalon}>
            <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class="mb-5">
                <div class="col-3 align-self-start nameOfSalon">
                  {/* <input required className="form-control" type="text" name="name_al" id="name_al" defaultValue={salonLocations?.name_al} placeholder="Emri Sallonit" /> */}
                  <SelectOption name="name_al" id="name_al" required >
                    <option hidden value={allCities?.find(elm => elm?.name == salonLocations?.name_al)?.name}>
                      {allCities?.find(elm => elm?.name == salonLocations?.name_al)?.name}
                    </option>
                    {allCities.map(el => {
                      return (
                        <>
                          <option value={el?.name} >{el?.name}</option>
                        </>
                      )
                    })}
                  </SelectOption>
                </div>
                {/* <div class="col-3 mt-3 align-self-center nameOfSalon">
                  <input className="form-control" type="text" name="name_en" id="name_en" defaultValue={salonLocations?.name_en} placeholder="Salon Name" />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="mt-2">Salon Images</p>
              </div>

              <div className="iconUploadImg">
                <div class="container">
                  <div class="row"
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="150"
                  >
                    <div className="col-12">
                      {salonLocations?.salon_imgs?.map((el, index) => {
                        return (
                          <>
                            <div className="row shaban">
                              <div className="col-4 divImageContainer">
                                <img src={`${process.env.REACT_APP_API}${el.img_url}`} alt={el.img_alt} className="imageEditSalon" onClick={handleLightboxOpen} />
                              </div>

                              {/* Show the Lightbox when isOpenLightbox is true */}
                              {isOpenLightbox && (
                                <Lightbox
                                  mainSrc={`${process.env.REACT_APP_API}${el.img_url}`}
                                  onCloseRequest={handleLightboxClose}
                                />
                              )}
                              <div className="col-4">
                                <div className="d-flex">
                                  <DivForLabelAndInput className="forDisable" disabled={editTrigger == index ? true : ""}>
                                    <LabelProfile > Image Description </LabelProfile>
                                    <InputProfileForm defaultValue={el.img_alt} type="text" onChange={(e) => setChangeImageDesc(e.target.value)} trigger={editTrigger} disabled={editTrigger != index ? true : ""} />
                                  </DivForLabelAndInput>
                                </div>
                              </div>
                              <div className="col-4 butonatEditDelete">
                                <div className="d-flex gap-2 forButtons">
                                  {editTrigger !== index ?
                                    <EditButtonSalon onClick={(e) => (e.preventDefault(), handleEditImage(index))}>Edit</EditButtonSalon>
                                    : <EditButtonSalon onClick={(e) => (e.preventDefault(), updateImageData(el))}>Save</EditButtonSalon>
                                  }
                                  {editTrigger !== index ?
                                    <DeleteButtonSalon onClick={(e) => { e.preventDefault(); DeleteImage(el.id) }}>Delete</DeleteButtonSalon>
                                    : <DeleteButtonSalon onClick={(e) => { e.preventDefault(); setEditTrigger(null) }}>Cancel</DeleteButtonSalon>
                                  }
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    {/* {salonLocations?.salon_imgs?.map((el, index) => {
                      console.log("el piqku mate", el)
                      return (
                        <>
                          <div class="col-sm-4"
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="150"
                          >
                            {handleImgIndex != salonLocations?.id ?
                              <img src={`${process.env.REACT_APP_API}${el.img_url}`} alt="asd" className="salonImages"
                                onClick={() => handleImage(index)} />
                              :
                              <button className="btn btn-md btn-danger salonImages" onClick={() => DeleteImage(el.id)}><strong>Confirm Delete </strong></button>
                            }
                          </div>
                        </>
                      )
                    })} */}
                  </div>
                </div>
              </div>
              <div className="border pt-5 pb-5 chooseFile">
                <img src={selectedImage} style={{ MaxWidth: "600px", maxHeight: '200px' }} alt="" />
                <label className="custom-file-upload">
                  <input type="file" name="img_url" id="img_url" onChange={(e) => { setImageUrl(e.target.files[0]); handleImageUpload(e.target.files[0]) }} />
                </label>
                <input type="text" placeholder="image title" className="form-control mt-1" onChange={(e) => { setImageTitle(e.target.value) }} />
              </div>
            </div>
            <p className="salonName" role="button" onClick={() => setShowdesc(true)}>Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
            {/* <div class="container"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="150"
            >
              <div class=" row mb-5">
                <div class="col align-self-start">
                  <textarea required className="form-control textAreaClass" name="desc_al" id="desc_al" defaultValue={salonLocations?.desc_al} placeholder="Pershkrimi Sallonit" cols="50" rows="7"></textarea>
                </div>

              </div>
            </div> */}
            <div className="row justify-content-between">
              <div className="col-6 ps-0">
                <textarea required className="form-control textAreaClass" name="desc_al" id="desc_al" defaultValue={salonLocations?.desc_al} placeholder="Pershkrimi Sallonit" cols="50" rows="7"></textarea>

              </div>
              <div className="col-6 pe-0">
                <textarea required className="form-control textAreaClass" name="desc_en" id="desc_en" defaultValue={salonLocations?.desc_en} placeholder="Description of Salon" cols="50" rows="7"></textarea>

              </div>
            </div>
            <div className="slideShowSalon mt-5">
              <button className="btn btn-md btn-success" type="submit">Edit</button>

              <hr />
            </div>
          </form>

          {/* SHEBRIMET */}

          {/* THINGS to CHANGE:
        -remove d-flex from divs
        - style all the divs, and elements
        -reponsivity
      -add other elements */}
          {showServices === true ?
            <div className="row">
              <div className="line"></div>
              <div className="container services">
                <div className="container">
                  <h6 className="my-4 browseServices">Choose Services:</h6>
                  <div className="container checkServices">
                    {/* <div className="all" onClick={() => handleAll()}>
                      <div role="button" className="d-flex flex-column pt-4 pb-4 align-items-center">
                            <p className="allText">Te gjitha</p>
                          </div>
                    </div> */}
                    <div className="col-12 editSalonServices">
                      {services?.map(el => {
                        return (
                          <>
                            <div
                              className={`d-flex justify-content-center servicediv mb-3 ${selectedTabs.includes(el.id) ? 'actives' : ''}`}
                              onClick={() => handleTabClick(el.id)}
                            >
                              <p className="pt-2"><strong>{el.name_al}</strong></p>
                            </div>
                            {selectedTabs.includes(el.id) && activeServiceIndex === el.id && (
                              <>
                                {subServices?.map(sub => {
                                  return (
                                    <>
                                      <div
                                        className="subService servicediv d-flex justify-content-center mb-2"
                                        data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="150"
                                      >
                                        <ul class="list-group w-100 mb-5">
                                          <strong className="text-danger">{sub?.name_al}</strong>
                                          {sub?.sub_sub_services?.map(response => {
                                            console.log('response gashi', sub)
                                            return (
                                              <li class="list-group-item">
                                                <div class="row">
                                                  <div class="col-sm">
                                                    <strong>{response.name_al}</strong>
                                                  </div>
                                                  <div class="col-2 timeResponsive">
                                                    <span>Price:</span>

                                                    < input className="inputDuration" type="number"
                                                      disabled={editingIndex != salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map(el => el?.id)}
                                                      defaultValue={salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map(el => el?.price)}
                                                      onChange={(e) => {
                                                        setPrice(e.target.value);
                                                      }}
                                                    />

                                                  </div>
                                                  <div class="col-3 timeResponsive">
                                                    Time:
                                                    {serviceID.includes(response.id) ?
                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map(el => {
                                                        const time = el.duration;
                                                        const timeParts = time.split(':');
                                                        const hours = parseInt(timeParts[0]);
                                                        const minutes = parseInt(timeParts[1]);
                                                        const totalMinutes = hours * 60 + minutes;
                                                        return (
                                                          <>
                                                            <input defaultValue={totalMinutes} disabled={editingIndex != el.id} className="inputDuration" type="number"
                                                              onChange={(e) => {
                                                                setValue(e.target.value);
                                                                setTimeValues((prevValues) => ({
                                                                  ...prevValues,
                                                                  [response.id]: e.target.value,
                                                                }));
                                                              }}
                                                            /><span>min</span>
                                                          </>
                                                        )
                                                      })
                                                      :
                                                      <>
                                                        <input type="number" className="inputDuration"
                                                          onChange={(e) => {
                                                            setValue(e.target.value);
                                                            setTimeValues((prevValues) => ({
                                                              ...prevValues,
                                                              [response.id]: e.target.value,
                                                            }));
                                                          }}
                                                        />
                                                        min
                                                      </>

                                                    }
                                                  </div>
                                                  <div class="col-sm">
                                                    is Favourite :{serviceID.includes(response.id) ?
                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map(el => {
                                                        return (

                                                          <input type="checkbox" disabled={editingIndex != el.id} defaultChecked={el.is_fav} onChange={(e) => { setIsFav(e.target.checked) }} />
                                                        )
                                                      })
                                                      :
                                                      <input type="checkbox" onChange={(e) => { setIsFav(e.target.checked) }} />
                                                    }
                                                  </div>
                                                  <div class="col-sm">
                                                    {console.log('elelelellelelele', el?.sub_services)}
                                                    {serviceID.includes(response.id) ?

                                                      salonLocations?.salon_sub_services?.filter(el => el.sub_sub_service_id == response.id).map((el, index) => {
                                                        return (
                                                          <>
                                                            <div className="d-flex gap-1">
                                                              <button type="button" className="btn btn-sm btn-secondary staffButtonSalon" onClick={() => handleModalStaf(response)}>Staff</button>
                                                              {editingIndex == el.id ? (
                                                                <>
                                                                  <button className="btn btn-sm btn-success editButtonSalon" onClick={(e) => EditSalonService(sub?.id, response.id, response.name_al, e, el.id, el.is_fav)}>save</button>
                                                                </>
                                                              ) : null}
                                                              {editingIndex == el.id ?
                                                                ""
                                                                :
                                                                <button className="btn btn-sm btn-primary addButtonSalon" onClick={() => setEditingIndex(el.id)}>Edit</button>
                                                              }
                                                              <button className="btn btn-sm btn-danger" onClick={() => handleModalDelete(el)}>Remove</button>
                                                            </div>
                                                          </>
                                                        )
                                                      })

                                                      :
                                                      <button className="btn btn-sm btn-primary addButtonSalon" onClick={() => handleAddModalStaf(sub?.id, response.id, response.name_al, value, selectedTabs[0], isFav)}>Add</button>
                                                    }
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="line"></div>
                {/* ABOUT  */}
                <div className="container">
                  <h6 className="aboutSalon my-5">Rreth sallonit:</h6>
                  <div className="row aboutLocation">
                    <div className="col-md-6 aboutLocationEdit">
                      <p className="aboutSalonNameEdit">Locations</p>
                      <div className="list-group-div">
                        <ul className="list-group mb-3">
                          <li className="list-group-item">
                            <div className="d-flex ">
                              <div className="col-sm-5 gap-2 d-flex location">
                                <div className="iconLocation">
                                  <img src={iconLocation} className="iconLocation" alt="" />
                                </div>
                                <input className="inputLocation mb-1"
                                  type="text"
                                  onChange={(e) => setEditName(e.target.value)}
                                  defaultValue={salonLocations?.street_address_al}
                                // onChange={(e)=> setStreet_address_al(e.target.value)}
                                />

                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <strong>Working Hours</strong>
                      <ul class="list-group list-group-flush listatBreak">
                        {console.log("workingHoursData", salonLocations)}

                        {sortedWorkingHours?.map((el, index) => {
                          const startTime = new Date(el.start_time);
                          const timeString = startTime.toISOString().split("T")[1].slice(0, 8);
                          const endTime = new Date(el.end_time);
                          const endtimeString = endTime.toISOString().split("T")[1].slice(0, 8);
                          const breakTime = new Date(el.brake_start);
                          const breaktimeString = breakTime.toISOString().split("T")[1].slice(0, 8);
                          const breakEnd = new Date(el.brake_end);
                          const breakendtimeString = breakEnd.toISOString().split("T")[1].slice(0, 8);;
                          return (
                            <>
                              <form onSubmit={(e) => handleEditWorkingHours(e, el)}>
                                <ul class="list-group groupList">
                                  <li class="row listText">
                                    <div className="text-danger">{el.day}</div>
                                    <div className="col-3"> <strong> Start H. </strong></div>
                                    <div className="col-3"> <strong>End H.</strong></div>
                                    <div className="col-3"> <strong>Break S.</strong></div>
                                    <div className="col-3"><strong>Break E.</strong></div>
                                  </li>
                                  <li class="list-group-item row gap-2 listGroup">
                                    <div className="timeListGroup">
                                      <input disabled={editTrigger != index} type="time" name="start_time" id="start_time" defaultValue={timeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="end_time" id="end_time" defaultValue={endtimeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="brake_start" id="brake_start" defaultValue={breaktimeString} className="col-3 inputWorkingHours" />
                                      <input disabled={editTrigger != index} type="time" name="brake_startbrake_end" id="brake_startbrake_end" defaultValue={breakendtimeString} className="col-3 inputWorkingHours" />
                                    </div>
                                    <div className="workingHours">
                                      <button className="btn btn-sm btn-edit col-2 editButtonSalon mr-0" type="button" onClick={() => setEditTrigger(index)}><strong>Edit</strong></button>
                                      <button className="btn btn-sm btn-primary col-2 addButtonSalon" type="submit" disabled={editTrigger != index}><strong>Save</strong></button>
                                      work day : <input disabled={editTrigger != index} type="checkbox" defaultChecked={el.work_this_day} name="work_this_day" id="work_this_day" />
                                    </div>
                                  </li>
                                </ul>
                              </form>
                            </>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="col-md-6 aboutLocationEdit">
                      <>
                        <h6 className="aboutSalon">Select Location Pin</h6>
                        <div>
                          <Map name_al={editName} name_en={locationValEn} addressId={addressId} />
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div>
                  <p>Termini - Salloni - {staticName}</p>
                </div>
              </div>
            </div>
            : ""}
        </Salon>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter,
    user_data: state?.data?.user_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
    setUserData: (data) => dispatch(setUserData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSalonView);