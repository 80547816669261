import React from "react";
import { AddButton, DeleteModal, PaginationStyle, TableStyle, SalonDetailsModal, SalonDetailsModalMobile, TextForNothing } from "../../components/styles/MainLayout";
import axios from "../../axios";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setSideBar, setUserData } from "../../redux/Functions/actions";
import { Link, useParams } from "react-router-dom";
import { AddButtonMobile, CardStyle } from "../../components/styles/MainLayoutResponsive";
import SearchInputHeader from "../../components/form/SearchInputHeader";

const Clients = (props) => {
    const { id } = useParams();
    const [dataToModal, setDataToModal] = useState(null);


    //MODAL STATES
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setEditIsOpen] = useState(false);
    const [singleUserData, setSingleUserData] = useState({})
    const [modalType, setModalType] = useState(null);
    //AXIOS API's


    const [allGenders, setAllGenders] = useState([]);
    const getAllGenders = () => {
        axios
            .get("/api/client/genders/all")
            .then((response) => {
                console.log("gender", response.data);
                setAllGenders(response.data)

            }).catch(err => {
                console.log(err)
            })
    }

    const [allSalons, setAllSalons] = useState([])

    const getData = () => {
        axios.get(`/api/store/users/salons_clients/${props?.user_data?.salon_id}`).then(data => {
            console.log('allusers get req', data.data);
            setAllSalons(data.data)
            setFilteredData(data.data.filter((el) => el.name))

        }).catch(err => console.log(err));
    }
    useEffect(() => {
        getData()
        getAllGenders()
    }, [])



    const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW
    //FILTER
    const [filteredData, setFilteredData] = useState();
    const filterByContent = async (filterValue) => {
        console.log("filterifilterifilteri", allSalons);
        if (filterValue) {
            const filteredArray = allSalons?.filter((el) =>
                el?.name?.toLowerCase().includes(filterValue?.toLowerCase())
            );
            setFilteredData(filteredArray);
        } else if (filterValue === "") {
            setFilteredData(allSalons);
        }
    };
    console.log("filteredData", filteredData)


    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 4;
    const endIndex = startIndex + 4;
    const numPages = Math.ceil(filteredData?.length / 4)

    const data = filteredData?.slice(startIndex, endIndex);
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    console.log("datadata", filteredData)
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    if (viewportSize > 800)
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />

                <TableStyle collapse={props?.sidebar}>
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                {/* <th scope="col-1">ID</th> */}
                                <th scope="col" key="Reserver">Name </th>
                                <th scope="col" key="Service">Surname</th>
                                <th scope="col" key="Worker">E-mail</th>
                                <th scope="col" key="Address">Phone number</th>
                                <th scope="col" key="Gender">Gender</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((el, index) => {
                                console.log("oladatadata", data)
                                return (

                                    <tr key={index}>
                                        {/* <th scope="row" className="col-1">
                                            {el?.id}
                                        </th> */}
                                        <td className="col">{el?.name}</td>
                                        <td className="col">{el?.surname}</td>
                                        <td className="col">{el?.email}</td>
                                        <td className="col">{el?.phone_number}</td>
                                        <td className="col">{props?.selected_lang == "AL" ?
                                            allGenders?.find((elm) => elm?.id == el?.gender)?.name_al
                                            :
                                            allGenders?.find((elm) => elm?.id == el?.gender)?.name_en}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </TableStyle>
                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                        currentPage={allSalons?.length > 7 ? true : false}
                    />
                </PaginationStyle>
                <Link to={'/addsalon'}>
                    <AddButton>
                        <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
                    </AddButton>
                </Link>
            </>
        )
    else {
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
                <div className="container">
                    {data?.map((el) => {
                        //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
                        const date = new Date(el?.created_at)
                        const formatDate = date?.toLocaleDateString()
                        return (
                            <>
                                <CardStyle>
                                    <div className="mb-3">
                                        <div className="row">
                                            <div className="col-8">
                                                <ul className="list-group gap-3 pt-2 pb-2 ps-3">
                                                    {/* <li class="list-group"><span><strong>ID</strong>: {el?.id}</span></li> */}
                                                    <li class="list-group"><span><strong>Name</strong>: {el?.name}</span></li>
                                                    <li class="list-group"><span><strong>Surname</strong> : {el?.surname}</span></li>
                                                    <li class="list-group"><span><strong>E-mail</strong> : {el?.email}</span></li>
                                                    <li class="list-group"><span><strong>Phone number</strong> : {el?.phone_number}</span></li>
                                                    <li class="list-group"><span><strong>Gender</strong> : {
                                                        props?.selected_lang == "AL" ?
                                                            allGenders?.find((elm) => elm?.id == el?.gender)?.name_al
                                                            :
                                                            allGenders?.find((elm) => elm?.id == el?.gender)?.name_en
                                                    }</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardStyle>
                            </>
                        )
                    })
                    }
                    {data?.length == 0 ? (
                        <TextForNothing>
                            <h6 className="mt-5">
                                "There is no data available"
                            </h6>
                        </TextForNothing>
                    ) : (
                        <PaginationStyle collapse={props?.sidebar}>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={numPages}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination justify-content-center"}
                                activeClassName={"active"}
                                currentPage={allSalons?.length > 7 ? true : false}
                            />
                        </PaginationStyle>
                    )}
                </div>
                <Link to={'/addsalon'}>
                    <AddButtonMobile>
                        <i type={"button"} className="fa-solid fa-circle-plus fa-xl iconsForCursor" style={{ color: "#FF5758" }}></i>
                    </AddButtonMobile>
                </Link>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        dataFilter: state?.data?.dataFilter,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
        setUserData: (data) => dispatch(setUserData(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);