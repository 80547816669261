import React from "react";
import "./addSalons.scss"
import { useState } from "react";
import { BsPen } from "react-icons/bs"
import Aos from "aos";
import Map from "./Map/Map";
import { Salon } from "../../../components/styles/MainLayout"
import iconLocation from "../../../assets/images/8666640-64.png"
import { setSideBar, setUserData } from "../../../redux/Functions/actions";
import { connect } from "react-redux";
import SearchInputHeader from "../../../components/form/SearchInputHeader";



const AddSalonView = (props) => {
  const [showServices, setShowServices] = useState(true)
  const [selectedImage, setSelectedImage] = useState(null);
  const [nameAl, setNameAl] = useState("")
  const [nameEn, setNameEn] = useState("")
  const [street_address_al, setStreet_address_al] = useState("")
  const [street_address_en, setStreet_address_en] = useState("")
  const [desc_al, setDesc_al] = useState("")
  const [desc_en, setDesc_en] = useState("")


  Aos.init();
  //API 
  console.log("propsat", props)

  //API
  //Functions 

  const handleImageUpload = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (e) {
      reader.readAsDataURL(e);
    }
  };
  return (
    <>
      <SearchInputHeader />


      <div className="container bgSalon">
        <Salon collapse={props?.sidebar}>

          <p className="salonName ms-3" role="button">City <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
          <div class="container"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="150"
          >
            <div class="mb-5">
              <div class="col-3 align-self-start nameOfSalon">
                <input required className="form-control" type="text" onChange={(e) => setNameAl(e.target.value)} placeholder="City" />
              </div>
              {/* <div class="col-3 mt-3 align-self-center nameOfSalon">
                <input required className="form-control" type="text" onChange={(e) => setNameEn(e.target.value)} placeholder="City" />
              </div> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <p className="mt-2">Choose Salon Image</p>
            </div>
            <div className="border pt-5 pb-5 chooseFile">
              <label className="custom-file-upload">
                <div className="iconUploadImg">
                  <img className="iconUpload mb-4" src={selectedImage === null ? IconUpload : selectedImage} />
                </div>
                <input required type="file" name="img_url" id="img_url" onChange={(e) => { setImageUrl(e.target.files[0]); handleImageUpload(e.target.files[0]) }} />
              </label>
            </div>
          </div> */}
          <p className="salonName" role="button">Description <BsPen style={{ fontSize: '20px', marginBottom: '20px' }} /></p>
          <div class="container"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="150"
          >
            <div class=" row mb-5">
              <div class="col align-self-start">
                <textarea required className="form-control textAreaClass" name="desc_al" id="desc_al" onChange={(e) => setDesc_al(e.target.value)} placeholder="Salon Description" cols="50" rows="7"></textarea>
              </div>
              {/* <div class="col align-self-center">
                <textarea required className="form-control textAreaClass" name="desc_en" id="desc_en" onChange={(e) => setDesc_en(e.target.value)} placeholder="Salon Description" cols="50" rows="7"></textarea>
              </div> */}
            </div>
          </div>


          {showServices === true ?
            <div className="row">
              <div className="line"></div>
              <div className="container services">
                {/* ABOUT  */}
                <div className="container">
                  <h6 className="aboutSalon my-5">Rreth sallonit:</h6>
                  <div className="row aboutLocation">
                    <div className="col-md-5">
                      <p className="aboutSalonNameEdit">Locations</p>
                      <div className="list-group-div  ">
                        <ul className="list-group mt-5  ">
                          <li className="list-group-item olaa">
                            <div className="d-flex ">
                              <div className="column">

                              <div className="col-sm-5 gap-1  olaaaaaaaa">
                                <div className="iconLocation">
                                  <img src={iconLocation} className="iconLocation" alt="" />
                                </div>
                                <input className="inputLocation mb-1"
                                  type="text"
                                  placeholder="Street name"
                                  onChange={(e) => setStreet_address_al(e.target.value)}
                                  />
                                {/* <div className="col-sm-7">
                                  <input className="inputLocation mb-1"
                                    placeholder="Street name"
                                    type="text"
                                    onChange={(e) => setStreet_address_en(e.target.value)}
                                    />
                                </div> */}
                                    </div>


                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7 ">
                      <div className="col-md-6">
                        <>
                          <h6 className="aboutSalon">Select Location Pin</h6>
                          <div style={{ height: '100vh' }}>
                            <Map nameAl={nameAl} nameEn={nameEn} desc_al={desc_al} desc_en={desc_en} street_al={street_address_al} street_en={street_address_en} salon_id={props?.user_data?.salon_id} />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3 salonSchedule">
                      <p>
                        ORARI
                      </p>
                    </div>
                  </div>
                </div>
                <hr />{" "}
                <div>
                  <p>Termini - Sallonet - Prishtinë</p>
                </div>
              </div>
            </div>
            : ""}
        </Salon>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter: state?.data?.dataFilter,
    user_data: state?.data?.user_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
    setUserData: (data) => dispatch(setUserData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSalonView);