import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalCloseButton, ModalDarkBackGround, Label, FormTitle, Input, FormControl, ModalAddButton, UsersForm } from "../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile } from "../styles/MainLayoutResponsive"
import { AddService } from "../form/ServiceForm";
import axios from "../../axios";
import { toast } from "react-toastify";

const MembershipModal = (props) => {
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    console.log("PROPSAT", props)
    const [name_al, setName_al] = useState("");
    const [is_fav, setIs_fav] = useState("");
    const [duration, setDuration] = useState("");



    const editBody = {
        id: props?.dataToModal?.id,
        name_al: name_al,
        is_fav: is_fav,
        duration: duration,
    }

    const handleEdit = (e) => {
        e.preventDefault();
        axios
            .put(`/api/store/salon_services/update`, editBody)
            .then((response) => {
                console.log("olaLabels", response);
                props?.handleModalClose();
                props?.getNewService();
                toast.success("Service edited sucessfully.")
            }).catch(err => {
                toast.error(err)
                console.log(err)
            })
    };


    if (props?.isOpen) {
        if (viewportSize > 800) {
            // ----DESKTOP VIEW---- //
            return (
                <>
                    <ModalPortal>
                        <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                        <ModalStyle>
                            <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                            {props?.type === "add" ?
                                <AddService />
                                :
                                props?.type === "edit" ?
                                    <>
                                        <FormTitle className="d-flex justify-content-center mt-3">Edit Service</FormTitle>
                                        <hr />
                                        <UsersForm onSubmit={handleEdit}>
                                            <FormControl>
                                                <Label >Service Name</Label>
                                                <Input  defaultValue={props?.dataToModal?.name_al} onChange={(e) => setName_al(e.target.value)} type="text" />
                                                <Label>Time</Label>
                                                <Input  defaultValue={props?.dataToModal?.duration} onChange={(e) => setDuration(e.target.value)} type="time" />
                                                <Label>Is Favourite</Label>
                                                <Input  defaultValue={props?.dataToModal?.is_fav} onChange={(e) => setIs_fav(e.target.value)} type="boolean" />
                                                    <ModalAddButton type="submit">Edit</ModalAddButton>
                                            </FormControl>
                                        </UsersForm>
                                    </>
                                    :
                                    ""
                            }
                        </ModalStyle>
                    </ModalPortal>
                </>
            )
        }
        //   ----MOBILE VIEW----- //
        else {
            return (
                <>
                    <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                    <ModalStyleMobile>
                        <ModalCloseButtonMobile type={"button"} onClick={() => props?.handleModalClose()}>X</ModalCloseButtonMobile>
                        {props?.type === "add" ?
                            <AddService />
                            :
                            props?.type === "edit" ?
                            <>
                                <FormTitle className="d-flex justify-content-center mt-3">Edit Service</FormTitle>
                                <hr />
                                <UsersForm onSubmit={handleEdit}>
                                    <FormControl>
                                        <Label >Service Name</Label>
                                        <Input  defaultValue={props?.dataToModal?.name_al} onChange={(e) => setName_al(e.target.value)} type="text" />
                                        <Label>Time</Label>
                                        <Input  defaultValue={props?.dataToModal?.duration} onChange={(e) => setDuration(e.target.value)} type="time" />
                                        <Label>Is Favourite</Label>
                                        <Input  defaultValue={props?.dataToModal?.is_fav} onChange={(e) => setIs_fav(e.target.value)} type="boolean" />
                                            <ModalAddButton type="submit">Edit</ModalAddButton>
                                    </FormControl>
                                </UsersForm>
                            </>
                            :
                            ""
                        }

                    </ModalStyleMobile>
                </>
            )
        }
    }
    else
        return null
}

export default MembershipModal
