import React from 'react'
import { useNavigate, useLocation } from "react-router";
import LogoTermini from "../../../assets/images/termini-beauty-logo.png"
import { ButtonR } from "../../../components/styles/MainLayout";
import Footer from '../../../components/LandingPage/Footer';
import "../../LandingPage/LandingPage.scss"
import { Buttons, FormLogin, InputEmail, InputPass, Loginbutton, Paragraf } from '../../styles/MainLayout';
import { useState } from 'react';
import axios from '../../../axios';
import sign from 'jwt-encode';
import { toast } from 'react-toastify';
const ChangePassword = () => {
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    // const navigateToRegister = () => {
    //     navigate('/register');
    // };

    const secretKey = process.env.REACT_APP_SECRET_KEY
    const params = useLocation()

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (newPassword === confirmPassword) {
            const body = {
                password: sign(newPassword, secretKey),
            }
            axios
                .post(`/api/auth/reset_password/change-password/${params.pathname.split('/')[2]}`, body)
                .then((response) => {
                    toast.success(response.data.message)
                    navigate('/')
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message);
                    console.log('error:', error);
                });
        }
        else {
            toast.error("Fjalëkalimet duhet të jenë të njejta!")
        }
    };

    return (
        <>
            <header className='headerLogin' style={{ background: 'white', width: '100%', top: '0', height: '100px' }}>
            </header>
            <div className=" loginLayout container" >
                <div className="logoTermini mt-5">
                    <img src={LogoTermini} alt="" />
                </div>
                <div className="backgroundImage mb-2">
                    <div className="mt-5 formLayout">
                        <div className="row">
                            <div className="col-md-6 rightText">
                                <h1 className='headingText'>Fjalëkalimi i ri!</h1>
                            </div>
                            <div className="col-md-6">
                                <FormLogin className="mt-4">
                                    <InputPass
                                        required
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Fjalëkalimi ri"
                                    // onChange={(e) => {
                                    //     setPassword(e.target.value);
                                    // }}
                                    >
                                    </InputPass>
                                    <InputPass
                                        required
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Konfirmo fjalëkalimin e ri "
                                    // onChange={(e) => {
                                    //     setPassword(e.target.value);
                                    // }}
                                    >
                                    </InputPass>
                                    <Buttons className="mt-3">
                                        <Loginbutton onClick={(e) => handleChangePassword(e)}>Ndrysho fjalëkalimin</Loginbutton>
                                        <ButtonR onClick={() => navigate('/')}>Anulo ndryshimin e fjalëkalimit</ButtonR>
                                    </Buttons>
                                    {/* <Paragraf>Keni harruar fjalëkalimin?</Paragraf> */}
                                </FormLogin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ChangePassword
