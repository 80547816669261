import React from "react";
import ProfileCard from "../../components/form/profile/profile"

const Profile = () => {
    return(
        <>
        <ProfileCard/>
        </>
    )
}
export default Profile