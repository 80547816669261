import styled, { css } from "styled-components";

export const CalendarStyleResponsive = styled.div`
margin-top: 33px;
    position: fixed;
    border-top: 6px solid rgb(255, 87, 88);
    border-radius: 12px;
    background-color: rgb(229, 229, 229);
    width: 100%;
    left: 21.5%; 
    height: 70%;
    padding: 2%;
`

export const CardStyle = styled.div`
border-top: 5px solid #FF5758;
box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
-webkit-box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
-moz-box-shadow: 4px 2px 11px 3px rgba(0,0,0,0.3);
border-radius: 15px;
background-color:white;
margin-top:55px;
width: 73%;
margin-left: 24%;


`
export const AddButtonMobile = styled.div`
top: unset;
bottom: 6%;
right: 5%;
position:fixed;
font-size:25px;
`
export const ModalStyleMobile = styled.div`
    position: fixed;
    z-index: 99;
    background-color: white;
    border-radius: 22px;
    width: 100%;
    height: 84.4%;
    bottom: 50px;
    overflow: scroll;

`
export const ModalCloseButtonMobile = styled.div`
border-bottom-left-radius: 12px;
    background-color: rgb(255, 87, 88);
    border: 0px;
    padding: 9px 14px;
    border-top-right-radius: 23px;
`


//Sidebar responsive
export const SubMenuItem = styled.div`
display: flex;
flex-direction: column;
`