import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import axios from '../../../../axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import '../editSalon.scss';

const MapContainer = (props) => {
    const [markerPosition, setMarkerPosition] = useState(null);
    const [salons, setsalons] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setInfoWindowFlag] = useState(true);
    // const [searchAddress, setSearchAddress] = useState('');
    const [mapPosition , setMapPosition] = useState("")
  const { id } = useParams();


  const handleMapClick = (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    const newPosition = { lat, lng };
    setMarkerPosition(newPosition);
  };

//   const handlePlaceSelect = (address) => {
//     setSearchAddress(address);
//     geocodeByAddress(address)
//       .then((results) => getLatLng(results[0]))
//       .then(({ lat, lng }) => {
//         const newPosition = { lat, lng };
//         setMapPosition(newPosition)
//         setMarkerPosition(newPosition);
//       })
//       .catch((error) => {
//         console.error('Error fetching coordinates', error);
//       });
//   };

  const getSalon = () => {
    axios
      .get(`/api/store/salon_address/single/${id}`)
      .then((data) => {
        console.log('SALON ADRES', data.data);
        setsalons(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editLocation = (e) => {
    e.preventDefault();
    let body = {
      salon_id: salons?.salon_id,
      id: id,
      street_address_al: props?.name_al,
      street_address_en: props?.name_en,
      latitude: markerPosition?.lat,
      longitude: markerPosition?.lng,
      created_by: 1,
    };
    axios
      .put('/api/store/salon_address/update', body)
      .then((data) => {
        console.log('data salon address', data.data);
        toast.success('Salon address edited successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSalon();
  }, []);

  return (
    <>
      <div className="col">
        {/* PlacesAutocomplete component */}
        {/* <PlacesAutocomplete
          value={searchAddress}
          onChange={setSearchAddress}
          onSelect={handlePlaceSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places...',
                })}
              />
              <div>
                {loading ? <div>Loading...</div> : null}
                {suggestions.map((suggestion, index) => {
                  const style = {
                    backgroundColor: suggestion.active ? 'lightgreen' : '#fff',
                  };
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, { style })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete> */}
        {/* Map */}
        <Map
          className="mapEditSalon"
          containerStyle={{
            marginTop: '15px',
            height: '725px',
            position: 'relative',
            border: '2px solid #b4dfc0',
          }}
          google={props.google}
          zoom={14}
          center={mapPosition == "" ? {lat: salons?.latitude, lng: salons?.longitude } : mapPosition}
          streetViewControl={false}
          onClick={handleMapClick}
        >
          {markerPosition && <Marker position={markerPosition} />}
          <Marker
            title={salons?.name_al}
            name={'Marker Name'}
            position={{ lat: salons?.latitude, lng: salons?.longitude }}
            onClick={(props, marker) => {
              setActiveMarker(marker);
            }}
          />
          {selectedElement ? (
            <InfoWindow
              visible={showInfoWindow}
              marker={activeMarker}
              onCloseClick={() => {
                setSelectedElement(null);
              }}
            >
              <div>
                <p className="infoWindow">{selectedElement.name_al}</p>
              </div>
            </InfoWindow>
          ) : null}
        </Map>
      </div>
      <div className="col">
        <form onSubmit={editLocation}>
          <button
            disabled={id == undefined}
            className="btn btn-md btn-success mt-2"
            type="submit"
          >
            Save
          </button>
        </form>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAx2mpUulK8e5rn9PB09xmSDDccbCoVMps',
})(MapContainer);
