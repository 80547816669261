import React from "react";
import { useState } from "react";
import Modal from "../../Modal/UserModals/Modal";
import { AddButton, TableStyle, PaginationStyle, TextForNothing } from "../../styles/MainLayout";
import { CardStyle, AddButtonMobile } from "../../styles/MainLayoutResponsive";
import SearchInputHeader from "../SearchInputHeader";
import { connect } from "react-redux";
import { setSideBar } from "../../../redux/Functions/actions";
import ReactPaginate from 'react-paginate';
import { useEffect } from "react";
import axios from "../../../axios"
import UserDeleteModal from "../../Modal/UserModals/UserDeleteModal";
import { Link } from "react-router-dom";

const UserResponsiveTable = (props) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dataToModal, setDataToModal] = useState(null);

  //AXIOS API
  const [allUsers, setAllUsers] = useState([])
  const UserData = () => {
    axios.get(`/api/store/users/salon_workers/${props?.user_data?.salon_id}`).then(data => {
      console.log('allusers get req', data);
      setAllUsers(data.data)
      setFilteredData(data.data)
    }).catch(err => console.log(err));
  }
  const [userRoles, setUserRoles] = useState();
  const getData = () => {
    axios
      .get("/api/store/user_roles/all")
      .then((data) => {
        console.log("allusers  roles get req", data);
        setUserRoles(data.data);
        // props.setLandData(data.data);
      })
      .catch((err) => console.log(err));
  };
  const [address, setAddress] = useState([])
  const getAddress = ()=>{
    axios.get(`/api/store/salon_address/all_addresses/${props?.user_data?.salon_id}`).then(
        data => {
            setAddress(data.data)
        }
        ).catch(err =>{
            console.log(err)
        })
}

  useEffect(() => {
    UserData();
    getData();
    getAddress();
  }, [])


  // FILTER FUNCTION
  const [filteredData, setFilteredData] = useState();
  const filterByContent = async (filterValue) => {
    if (filterValue) {
      const filteredArray = allUsers.filter((el) =>
        el?.name?.toLowerCase().includes(filterValue?.toLowerCase())
      );
      setFilteredData(filteredArray);
    } else if (filterValue === "") {
      setFilteredData(allUsers);
    }
  };


  // FILTER FUNCTION

  //MODAL STATES
  const [isOpen, setIsOpen] = useState(false);
  const [singleUserData, setSingleUserData] = useState({})
  const [modalType, setModalType] = useState(null);
  //MODAL STATES

  const [viewportSize, setViewportSize] = useState(window.innerWidth); //DESKTOP VIEW & MOBILE VIEW

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(false);
  const startIndex = currentPage * 4;
  const endIndex = startIndex + 4;
  const numPages = Math.ceil(filteredData?.length / 4)

  const data = filteredData?.slice(startIndex, endIndex);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  //PAGINATION

  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };

  const handleModalEdit = (data) => {
    setIsOpen(true);
    setModalType("edit");
    setSingleUserData(data);
  };
  const handleModalView = (data) => {
    setIsOpen(true);
    setModalType("view");
    setSingleUserData(data);

  };

  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setModalType("delete");
    setDataToModal(data)
  }

  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDelete(false)

  };

  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });

  if (viewportSize > 800) {
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <Modal
            getNewUsers={() => UserData()}
            data={singleUserData}
            type={modalType}
            isOpen={isOpen}
            handleModalClose={handleModalClose}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <UserDeleteModal
            getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
        
        <TableStyle collapse={props?.sidebar}>
          <table class="table table-hover">
            <thead>
              <tr>
                {/* <th scope="col-1">ID</th> */}
                <th scope="col-4">Full Name</th>
                <th scope="col-5">Email</th>
                <th scope="col-4">Role </th>
                <th scope="col-6">Phone Number</th>
                <th scope="col-3">Address</th>
                <th scope="col-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el) => {

                return (
                  <>
                    {console.log("userRoles", userRoles)}

                    <tr>
                      {/* <th scope="row" className="col-1">
                        {el?.id}
                      </th> */}
                      <td className="col-4">{el?.name + " " + el?.surname}</td>
                      <td className="col-5">{el?.email}</td>
                      <td class="col-sm-4">{
                        userRoles?.find((elm) => elm?.id == el?.role_id)?.role_al
                      }</td>
                      <td className="col-6">{el?.phone_number}</td>
                      <td className="col-3">{address?.find((elm) => elm?.id == el?.salon_address_id)?.name_al}</td>

                      <td className="col-3">
                        <div className="d-flex gap-3">
                          <Link to={`/profile/${el.id}/${el?.uuid}`}>
                            <i
                              class="fa-regular fa-eye mt-1"

                            />
                          </Link>
                          <i
                            class="fa-regular fa-trash-can mt-1 iconsForCursor"
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </TableStyle>

        <PaginationStyle collapse={props?.sidebar}>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={numPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
          // currentPage={data.length > 7 ? true : false}
          />
        </PaginationStyle>
        <AddButton>
          <i

            type={"button"}
            className="fa-solid fa-circle-plus fa-xl"
            style={{ color: "#FF5758" }}
            onClick={() => handleModalAdd()}
          ></i>
        </AddButton>
      </>
    );
  } else
    return (
      <>
        <SearchInputHeader filterByContent={filterByContent} />
        {isOpen === true ? (
          <Modal
            data={singleUserData}
            isOpen={isOpen}
            type={modalType}
            handleModalClose={handleModalClose}
            getNewUsers={UserData}
          />
        ) : (
          ""
        )}
        {isOpenDelete === true ? (
          <UserDeleteModal
            getNewUsers={() => UserData()}
            isOpen={isOpenDelete}
            type={modalType}
            dataToModal={dataToModal}
            handleModalClose={handleModalClose}
          />
        ) : (

          ""
        )}
        <div className="container">
          {data?.map((el) => {
            //CREATED AT FORMAT TO DATE MONTH YEAR ONLY
            const date = new Date(el?.created_at)
            const formatDate = date.toLocaleDateString()
            return (
              <>
                <CardStyle>
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-8">
                        <ul class="list-group gap-3 pt-2 pb-2 ps-3">
                          {/* <li class="list-group">Id: {el?.id}</li> */}
                          <li class="list-group">
                            <strong>
                              Full Name :
                            </strong>
                            {el?.name} {el?.surname}
                          </li>
                          <li class="list-group">
                            <strong>
                              Email :
                            </strong>
                            {el?.email}
                          </li>
                          <li class="list-group">
                            <strong>
                              phone number:
                            </strong>
                            {el?.phone_number}</li>
                          <li class="list-group">
                            <strong>
                              Address:
                            </strong>
                            {address?.find((elm) => elm.id == el.salon_address_id)?.name_al}</li>
                          <li class="list-group">
                            <strong>
                              Created_at :
                            </strong>
                            {formatDate}
                          </li>
                          <li class="list-group">
                            <strong>
                              Role :
                            </strong>
                            {
                              userRoles?.find((elm) => elm.id == el.role_id)?.role_al
                            }
                          </li>
                        </ul>
                      </div>
                      <div className="col-3 pt-2">
                        <div className="d-flex gap-3">
                        <Link to={`/profile/${el.id}/${el?.uuid}`}>
                            <i
                              class="fa-regular fa-eye  mt-2"

                            />
                          </Link>
                          <i
                            role="button"
                            class="fa-regular fa-trash-can mt-2"
                            onClick={() => handleModalDelete(el)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardStyle>
              </>
            );
          })}
          {data === undefined ? (
            <TextForNothing>
              <h6 className="mt-3">
                "There is no data available"
              </h6>
            </TextForNothing>
          ) : (
            <PaginationStyle collapse={props?.sidebar}>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={numPages}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center"}
                activeClassName={"active"}
              />
            </PaginationStyle>
          )}


          <AddButtonMobile onClick={() => handleModalAdd()}>
            <i
              class="fa-solid fa-circle-plus d-flex flex-row-reverse fa-xl"
              style={{ color: "#FF5758" }}
            />
          </AddButtonMobile>

        </div>
      </>
    );
};
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    user_data: state?.data?.user_data,
    dataFilter: state?.data?.dataFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserResponsiveTable);
// export default UserResponsiveTable;
