import React from 'react'
import "./LandingPage.scss"
import ImageLogo from "../../assets/images/termini-beauty-logo.png"
import ImageLogo1 from "../../assets/images/company-logo/harrisia-logo-01.png"

const Footer = () => {
  return (
    <>
      <div className='footerLayout'>
        <div className=' iconsLogoss'>
          <div className="container">
            <div className="row imageTerminet">
              <div className="col-md-11 col-12  imageLayout">
                <img src={ImageLogo} alt="" className='logoImage' />
              </div>
              <div className="iconsLogo col-md-1 col-12">
                <div className="icons">
                  <div className="iconInsta">
                    <a class="fa-brands fa-instagram" target="_blank"
                      href="https://www.instagram.com/termini.beauty/"
                      style={{ textDecoration: "none", color: '#FF6768' }}>

                    </a>
                  </div>
                  <div className="iconInsta">
                    <a class="fa-brands fa-tiktok " target="_blank"
                      href="https://www.tiktok.com/@termini.beauty?_t=8ij93FFqztG&_r=1"
                      style={{ textDecoration: "none", color: '#FF6768' }}>
                    </a>
                  </div>
                  <div className="iconInsta">
                    <a class="fa-brands fa-facebook-f" target="_blank"
                      href="https://www.facebook.com/terminionline"
                      style={{ textDecoration: "none", color: '#FF6768' }}>

                    </a>
                  </div>
                  <div className="iconInsta">
                    <a class="fa-brands fa-youtube" target="_blank"
                      href="https://www.youtube.com/@TerminiBeauty"
                      style={{ textDecoration: "none", color: '#FF6768' }}>

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="logoHarrisia">
            <div className=" imageLayout">
              <img src={ImageLogo1} alt="" className='logoImages' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
