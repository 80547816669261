import React, { useState } from 'react'
import {
  IconsHeader,
  IconHeader,
} from "../styles/MainLayout";
import logoutBtn from "../../assets/menuIcons/logout.png"
import { Link } from 'react-router-dom';
import { CgProfile } from "react-icons/cg"
import { setLoggedIn, setUserData, setUserToken } from '../../redux/Functions/actions';
import { connect } from 'react-redux';

const HeaderIcons = (props) => {
  const [checked, setChecked] = useState(true)
  function handleLogOut(e) {
    e.preventDefault();
    props?.setLoggedIn(false)
    props.setUserData([])
    props.setUserToken(null)
    // window.location.href = "/"
  }
  return (
    <>
    {console.log('props dion', props)}
      <IconsHeader className='mt-4'>
        <Link to={`/profile/${props?.user_data?.user_id}/${props?.user_data?.uuid}`} style={{ color: 'black' }}>
          <CgProfile style={{ fontSize: "25px" }} />
        </Link>
        <IconHeader>
          <img onClick={(e) => handleLogOut(e)} src={logoutBtn} style={{ width: '25px' }} />
        </IconHeader>
      </IconsHeader>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    // selected_lang: state.data.selected_lang,
    // logged_in: state.data.logged_in,
    // sidebar: state?.data?.sidebar,
    user_data: state?.data?.user_data,
    // dataFilter: state?.data?.dataFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setSideBar: (data) => dispatch(setSideBar(data)),
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderIcons);

// export default HeaderIcons
