export const MenuItems =[

        {
            id:"1",
            menu_name:"Staff",
            menu_icon:"../../menuIcons/users.png",
            path:"/",
        },
        {
            id:"1",
            menu_name:"Appointments",
            menu_icon:"../../menuIcons/appointments.png",
            path:"/appointments",
        },
        {
            id:"2",
            menu_name:"Memberships",
            menu_icon:"../../menuIcons/membership.png",
            path:"/membership"

        },
        {
            id:"4",
            menu_name:"Salons",
            menu_icon:"../../menuIcons/salon.png",
            path:"/salon"

        },
        {
            id:"5",
            menu_name:"Clients",
            menu_icon:"../../menuIcons/clients.png",
            path:"/clients"

        },
        // {
        //     "id":"5",
        //     "menu_name":"Services",
        //     "menu_icon":"../../menuIcons/scissors.png",
        //     // "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
        //     "path":"/services"

        // },
     
    ]

    export const submenuItems =[
        {
                        id:1,
                        submenuTitle:"Statistic",
                        submenuLogo: "../../menuIcons/chart.png",
                        submenuContent:["Clients" , "Staff" , "Salons" , "Appointments"],
                        linkTo: "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Statistic",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
                        "linkTo": "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Statistic",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
                        "linkTo": "/test"
                    },
                    {
                        "id":1,
                        "submenuTitle":"Test",
                        "submenuLogo" : "../../menuIcons/chart.png",
                        "submenuContent":["test1" , "test2" , "test3" , "test4"],
                        "linkTo": "/test1"
                    }

    ]

    // {

    
    //     submenuItems :[
    //         {
    //             id:1,
    //             submenuTitle:"Statistic",
    //             submenuLogo: "../../menuIcons//chart.png",
    //             submenuContent:["Clients" , "Staff" , "Salons" , "Appointments"],
    //             linkTo: "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Statistic",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
    //             "linkTo": "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Statistic",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["Clients" , "Staff" , "Salons" , "Appointments"],
    //             "linkTo": "/test"
    //         },
    //         {
    //             "id":1,
    //             "submenuTitle":"Test",
    //             "submenuLogo" : "../../menuIcons//chart.png",
    //             "submenuContent":["test1" , "test2" , "test3" , "test4"],
    //             "linkTo": "/test1"
    //         }
    //     ]
    
    // }


