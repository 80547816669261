import React, { useEffect } from 'react'
import AppointmentCalendar from "../../components/form/appointmentsCalendar"
import AppointmentTable from '../../components/form/AppointmentResponsiveTable'
import { useState } from 'react'
import { Switch } from 'antd'
import { SwitchStyle, SwitchStyle2, ApppointmentButton } from '../../components/styles/MainLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { setLoggedIn, setExpiredToken, setUserToken, setUserData } from '../../redux/Functions/actions';
import { connect } from "react-redux";
import PendingAppointments from '../../components/form/PendingAppointments'
import axios from '../../axios'
const Appoinments = (props) => {
  // console.log('ramadani', props)
  const location = useLocation()
  const navigate = useNavigate()
  // console.log('location', location)

  const [pending, setPending] = useState([])

  const pendingAppointment = () => {

    axios.get(`api/store/appointments/pendingAppointments/${props?.salonId}`)
      .then((response) => {
        setPending(response.data.pending_appointments)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log('pending', pending)
  useEffect(() => {
    pendingAppointment()
    if (location.pathname === "/appointments/pending") {
      props?.setView(3)
    }

  }, [])
  return (
    <>
      {props?.view == 0 ?
        <>
          <SwitchStyle>
            <ApppointmentButton className='background_calendar_button' onClick={() => { props?.setView(0); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Kalendari</ApppointmentButton>
            <ApppointmentButton onClick={() => { props?.setView(1); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Lista</ApppointmentButton>
            <ApppointmentButton onClick={() => { props?.setView(2); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Në pritje <span className='text-danger'>{pending}</span></ApppointmentButton>
          </SwitchStyle>
          <AppointmentCalendar setView={() => props?.setView()} calendarView={props?.view} />
        </>
        :
        props?.view == 1 ?
          <>
            <SwitchStyle2>
              <ApppointmentButton onClick={() => { props?.setView(0); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Kalendari</ApppointmentButton>
              <ApppointmentButton className='background_calendar_button' onClick={() => { props?.setView(1); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Lista</ApppointmentButton>
              <ApppointmentButton onClick={() => { props?.setView(2); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Në pritje <span className='text-danger'>{pending}</span></ApppointmentButton>
            </SwitchStyle2>
            <AppointmentTable pendingAppointment={pendingAppointment}/>
          </>
          : <>
            <SwitchStyle2>
              <ApppointmentButton onClick={() => { props?.setView(0); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Kalendari</ApppointmentButton>
              <ApppointmentButton onClick={() => { props?.setView(1); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Lista</ApppointmentButton>
              <ApppointmentButton className='background_calendar_button' onClick={() => { props?.setView(2); navigate(`${props?.user_data?.role === 4 ? '/': '/appointments'}`) }}>Në pritje <span className='text-light'>{pending}</span></ApppointmentButton>
            </SwitchStyle2>
            <PendingAppointments pendingAppointment={pendingAppointment} />
          </>
      }
    </>
  )
}

// export default Appoinments
const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    user_data: state?.data?.user_data,
    expire_token: state?.data?.expire_token
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setExpiredToken: (data) => dispatch(setExpiredToken(data)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Appoinments);
