import React from 'react'
import LoginForm from '../../components/form/LoginForm/LoginForm'
import Photo from "../../assets/images/termini-beauty-logo.png";
import Aos from "aos";
import "../../components/LandingPage/LandingPage.scss"
import { useNavigate } from "react-router";
import LogoTermini from "../../assets/images/termini-beauty-logo.png"
import {
  LoginLayout,
  Loginform,
  Heading,
  FormLayout,
  FormRegister,
  Image,
  Heading3,
  Text,
  Paragraph,
  ButtonRegister,
  ButtonR,
} from "../../components/styles/MainLayout";
import Footer from '../../components/LandingPage/Footer';
import "../../components/LandingPage/LandingPage.scss"
const Login = () => {

  const navigate = useNavigate();

  const navigateToRegister = () => {
    // 👇️ navigate to /contacts
    navigate('/register');
  };

  Aos.init();

  return (
    <>
      <header className='headerLogin' style={{ background: 'white', width: '100%', top: '0', height: '100px' }}>
      </header>
      <div className=" loginLayout container" >
        <div className="logoTermini mt-5">
          <img src={LogoTermini} alt="" />
        </div>
        <div className="backgroundImage mb-2">

          <div className="mt-5 formLayout">
            <div className="row">
              <div className="col-md-6 rightText">
                <h1 className='headingText'>Mirësevini!</h1>
              </div>
              <div className="col-md-6">
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Login
