import React from "react";
import SalonTable from "../../components/form/salons/SalonResponsiveTable";

const Salons = () => {
    return(
        <>
        <SalonTable/>
        </>
    )
}
export default Salons;