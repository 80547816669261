import React from 'react'
import "./LandingPage.scss"
import ImageLogo from "../../assets/images/termini-beauty-logo.png"
import { useNavigate } from "react-router";


const Header = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/login');
  };
  const navigateToRegister = () => {
    navigate('/register');
  };
  return (
    <>
      <div className="headerLayout">
        <div className='container headerLayoutDiv'>
          <div className="imageLayout">
            <img src={ImageLogo} alt="" className='logoImage' />
          </div>
          <div className="buttonsForLogin">
            <button className='loginButton' onClick={navigateToLogin}>Kyçu</button>
            <button className='registerButton' onClick={navigateToRegister}>Regjistrohu</button>
          </div>
        </div>

      </div>
    </>
  )
}

export default Header
