import React, { useEffect, useState } from 'react';
import './MultiStepForm.scss';
import Map from '../AddSalon/Map/Map';
import axios from '../../../axios';
import SearchInputHeader from '../../../components/form/SearchInputHeader';
import LeavePageAlert from './HandleRefresh';
import { current } from '@reduxjs/toolkit';
import { DivForLabelAndInput, InputProfileForm, LabelProfile, SelectOption } from '../../../components/styles/MainLayout';
import { setSideBar, setUserData } from '../../../redux/Functions/actions';
import { connect } from 'react-redux';
import sign from 'jwt-encode';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import AddLocationModal from "../../../components/Modal/salonModals/addLocation"

const MultistepForm = (props) => {
    const [step, setStep] = useState(1);
    const [services, setServices] = useState()
    const [selectedTabs, setSelectedTabs] = useState([]);
    const [subServices, setSubservices] = useState([])
    const [value, setValue] = useState("")
    const [activeServiceIndex, setActiveServiceIndex] = useState(null);
    const [showSubServices, setSubShowServices] = useState(false)
    const [isFav, setIsFav] = useState(null)
    const [completedSteps, setCompletedSteps] = useState([]);
    const [city, setCity] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [street, setStreet] = useState("")
    const [imageTitle, setImageTitle] = useState("")
    const [addressId, setAddressId] = useState()
    const [isOpen, setIsOpen] = useState(false)
    const [modalType, setModalType] = useState('')
    const [salonServiceId, setSalonServiceId] = useState([])
    const [dataToModal, setDataToModal] = useState('')
    const [users, setUsers] = useState([])
    const [allCities, setAllCities] = useState([])
    const [timeValues, setTimeValues] = useState({});

    const secretKey = process.env.REACT_APP_SECRET_KEY

    const serviceID = salonServiceId.filter(elem => elem.salon_address_id == addressId).map(el => el?.sub_sub_service_id)

    const getSalonAddressService = () => {
        axios.get(`/api/store/salon_services/all`).then(
            response => {
                console.log("salon address single", response)
                setSalonServiceId(response.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }
    const getServices = () => {
        axios.get('/api/store/services/all').then(
            data => {
                console.log("serviset", data.data)
                setServices(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    const getCities = () => {
        axios.get('/api/cities/all/').then(
            data => {
                console.log("ola", data.data)
                setAllCities(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }
    const handleTabClick = (id) => {
        axios.get(`/api/store/sub_services/single/${id}`)
            .then(data => {
                console.log("sub service data", data.data)
                setSubservices(data.data)
            }).catch(err => {
                console.log(err)
            })
        if (selectedTabs.includes(id)) {
            setSelectedTabs(selectedTabs.filter((tabId) => tabId !== id));
            setActiveServiceIndex(null);
        } else {
            setSelectedTabs([id]);
            setActiveServiceIndex(id);
        }
        setSubShowServices(true);
    };

    const nextStep = () => {
        setStep(step + 1);
        setCompletedSteps([...completedSteps, step]);
    };

    const prevStep = () => {
        setStep(step - 1);
    };


    //API CALLS
    const addUser = (e) => {
        let passEnc = sign(e.target?.password?.value, secretKey)
        console.log('eventGashji', e)
        e.preventDefault();
        let body = {
            name: e?.target?.name?.value,
            surname: e?.target?.surname?.value,
            email: e?.target?.email?.value,
            password: passEnc,
            salon_id: props?.user_data?.salon_id,
            gender: e?.target?.gender?.value,
            phone_number: e?.target?.phoneNumber?.value,
            role_id: 4,
            default_language: 1,
            created_by: props?.user_data?.user_id,
            salon_address_id: addressId,
            username: e.target.username.value
        }
        console.log('postdata', body)
        axios.post("/api/store/users/", body)
            .then(data => {
                console.log("users added" + data.data)
                toast.success("User Added Sucesfully!")
                getUsers()

            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
        e.target.reset();
    }
    const getUsers = () => {
        axios.get('/api/store/users/all').then(
            data => {
                setUsers(data.data.filter(el => el.salon_address_id == addressId))
            }
        ).catch(err => {
            console.log(err)
        })
    }
    const handleModalClose = () => {
        setIsOpen(false);

    };
   const handleAddModalStaf = (subid, responseid, responsename_al, value, serviceId, isFav, addressId) => {
        if (timeValues[responseid] === undefined || timeValues[responseid] === "") {
            toast.warning("Please select a time value");
        } else {
            setIsOpen(true);
            setModalType("add");
            setDataToModal([subid, responseid, responsename_al, value, serviceId, isFav, addressId]);
        }
    };
    useEffect(() => {
        getSalonAddressService()
        getCities()
    }, [])

    const StepIndicator = ({ currentStep, completedSteps }) => {
        const steps = [1, 2, 3];

        return (
            <div className="step-indicator">
                {steps.map((step, index) => (
                    <div
                        key={index + 1}
                        className={`step1 ${completedSteps.includes(index + 1) ? 'completed' : ''} ${currentStep === index + 1 ? 'active' : ''}`}
                    >
                        {step}
                    </div>
                ))}
            </div>
        );
    };
    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="step">
                        <h2>Step 1: Add Salon Address</h2>
                        <DivForLabelAndInput>
                            <SelectOption onChange={(e) => setCity(e.target.value)} required>
                                <option>Select City</option>
                                {allCities.map(el => {
                                    return (
                                        <>
                                            <option value={el?.name}>{el?.name}</option>
                                        </>
                                    )
                                })}
                            </SelectOption>
                        </DivForLabelAndInput>
                        <DivForLabelAndInput>
                            <LabelProfile >Salon Description</LabelProfile>
                            <textarea
                                className='form-control'
                                id="salonDescription"
                                name="salonDescription"
                                onChange={(e) => { setDescription(e.target.value) }}
                                required
                            />
                        </DivForLabelAndInput>
                        <DivForLabelAndInput>
                            <LabelProfile >Salon Image</LabelProfile>
                            <input
                                className='mt-2'
                                type='file'
                                required
                                onChange={(e) => { setImage(e.target.files[0]) }}
                            />
                            <InputProfileForm
                                type='text'
                                placeholder='image title'
                                required
                                className='mt-2'
                                onChange={(e) => { setImageTitle(e.target.value) }}
                            />
                        </DivForLabelAndInput>
                        <DivForLabelAndInput>
                            <LabelProfile>Street Name</LabelProfile>
                            <InputProfileForm
                                type="text"
                                id="salonAddress"
                                name="salonAddress"
                                onChange={(e) => { setStreet(e.target.value) }}
                                required
                            />
                        </DivForLabelAndInput>
                        <strong>Salon Pin</strong>
                        <Map setAddressId={setAddressId} nextStep={nextStep} salon_id={props?.user_data?.salon_id} city={city} street={street} description={description} imageTitle={imageTitle} image={image} />
                    </div>
                );
            case 2:
                return (
                    <>
                        <div className="step">
                            <div className="d-flex gap-3 mb-2">
                                <h2>Step 2: Add Workers</h2>
                                <div className="d-flex col-sm-4">

                                    <select className='form-select'>
                                        <option value="">workers list</option>
                                        {users.length == 0 ?
                                            <option value="">no users</option>
                                            :
                                            ""
                                        }
                                        {users?.map(el => {
                                            return <option value="">{el?.name + " " + el?.surname}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <form onSubmit={addUser}>
                                <DivForLabelAndInput>
                                    <LabelProfile>Name</LabelProfile>
                                    <InputProfileForm
                                        type="text"
                                        id="name"
                                        name="name"
                                        required
                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >Surname</LabelProfile>
                                    <InputProfileForm
                                        type="text"
                                        id="surname"
                                        name="surname"
                                        required
                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >username</LabelProfile>
                                    <InputProfileForm
                                        type="text"
                                        id="username"
                                        name="username"
                                        required
                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >Phone Number</LabelProfile>
                                    <InputProfileForm
                                        type="number"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        required
                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >Email</LabelProfile>
                                    <InputProfileForm
                                        type="email"
                                        id="email"
                                        name="email"
                                        required

                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >Password</LabelProfile>
                                    <InputProfileForm
                                        type="password"
                                        id="password"
                                        name="password"
                                        required
                                    />
                                </DivForLabelAndInput>
                                <DivForLabelAndInput>
                                    <LabelProfile >Gender</LabelProfile>
                                    <SelectOption id='gender' name='gender'>
                                        <option value={1}>Mashkull</option>
                                        <option value={2}>Femer</option>
                                    </SelectOption>
                                </DivForLabelAndInput>
                                {/* <button onClick={prevStep}>Previous</button> */}
                                <div className="d-flex gap-3">
                                    <button className='AddWorker Navbutton' type='submit'>Add</button>
                                    <button className='Navbutton' type='button' onClick={() => { nextStep(); getServices() }}>Next</button>
                                </div>
                            </form>
                        </div>

                    </>
                );
            case 3:
                return (
                    <>
                        {isOpen === true ? (
                            <AddLocationModal
                                isOpen={isOpen}
                                handleModalClose={handleModalClose}
                                data={dataToModal}
                                // getSalon={getSalon}
                                getSalonAddressService={getSalonAddressService}
                                type={modalType}
                            />
                        ) : (
                            ""
                        )}
                        <div className="step">
                            <h2>Step 3: Choose Services</h2>
                            {services?.map(el => {
                                return (
                                    <>
                                        <div
                                            className={`d-flex justify-content-center servicediv mb-3 ${selectedTabs.includes(el?.id) ? 'actives' : ''}`}
                                            onClick={() => handleTabClick(el?.id)}
                                        >
                                            <p className="pt-2"><strong>{el?.name_al}</strong></p>
                                        </div>
                                        {selectedTabs?.includes(el?.id) && activeServiceIndex == el?.id && (
                                            <>
                                                {subServices?.map(sub => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="subService servicediv d-flex justify-content-center mb-2"
                                                                data-aos="fade-down"
                                                                data-aos-easing="linear"
                                                                data-aos-duration="150"
                                                            >
                                                                <ul class="list-group w-100 mb-5">
                                                                    <strong className="text-danger">{sub?.name_al}</strong>
                                                                    {sub?.sub_sub_services?.map((response, index) => {
                                                                        return (
                                                                            <li class="list-group-item">
                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        <strong>{response?.name_al}</strong>
                                                                                    </div>
                                                                                    <div class="col-3 timeResponsive">
                                                                                        time:<input disabled={serviceID.includes(response.id)} required type="number" className="inputDuration"
                                                                                         onChange={(e) => {
                                                                                            setValue(e.target.value);
                                                                                            setTimeValues((prevValues) => ({
                                                                                                ...prevValues,
                                                                                                [response.id]: e.target.value,
                                                                                            }));
                                                                                        }} /> min
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        is Favourite :<input disabled={serviceID.includes(response.id)} type="checkbox" onChange={(e) => { setIsFav(e.target.checked) }} />
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        {serviceID.includes(response.id) ? "" : <button className="serviceAddBtn" onClick={() => handleAddModalStaf(sub?.id, response.id, response.name_al, value, selectedTabs[0], isFav, addressId)}>Add</button>}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                );
                            })}
                            <div className="d-flex gap-3">
                                <button className='Navbutton' onClick={prevStep}>Previous</button>
                                <button className="Navbutton" onClick={nextStep}>Done</button>
                            </div>
                        </div>
                    </>
                );
            case 4:
                return (
                    <div className="step">
                        <h1 className='d-flex justify-content-center text-danger'>Salon Address Added !</h1>
                        <Link className='text-dark' to={`/editsalon/${addressId}`}>
                            <button className='d-flex mx-auto mt-5 Navbutton'>Go To Salon Address</button>
                        </Link>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <LeavePageAlert />
            {/* <SearchInputHeader /> */}
            <StepIndicator currentStep={step} completedSteps={completedSteps} />
            <div className="multistep-form mt-3 bg-light">
                {renderStep()}
            </div>
        </>
    )
};
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        dataFilter: state?.data?.dataFilter,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data)),
        setUserData: (data) => dispatch(setUserData(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultistepForm);
// export default MultistepForm;