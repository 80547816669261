

import React from "react";
import { useState } from "react";
import { PaginationStyle, TableStyle, Warning } from "../styles/MainLayout"
import { CardStyle } from "../styles/MainLayoutResponsive"
import AppointmentModal from "../Modal/AppointmentModal";
import AppointmentDeleteModal from "../Modal/AppointmentDeleteModal";
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import SearchInputHeader from "./SearchInputHeader";
import axios from "../../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";

const AppointmentTable = (props) => {
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState(null)
    const [appointmentData, setAppointmentData] = useState([])
    const [modalData, setModalData] = useState()
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [appcos, setAppCos] = useState()
    const [overSubAppointments, setOverSubAppointments] = useState()

    //AXIOS
    const getAppointments = () => {
        if (props?.user_data?.role == 4) {
            axios.get(`api/store/appointments/singleWorker/${props?.user_data?.user_id}`)
                .then((response) => {
                    setAppointmentData(response.data.data)
                    setFilteredData(response.data.data)
                    const overappointments = response.data.over_limit_appt
                    setOverSubAppointments(overappointments)

                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            axios.get(`api/store/appointments/singleSalon/${props?.user_data?.salon_id}`)
                .then((response) => {
                    setAppointmentData(response.data.data)
                    setFilteredData(response.data.data)
                    const overappointments = response.data.over_limit_appt
                    setOverSubAppointments(overappointments)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    //AXIOS
    const [subservices, setSubServices] = useState([])
    const getServices = () => {
        axios.get(`/api/store/salon_services/all`).then(
            data => {
                console.log("serviset aaaaaaaaaaaaa", data.data)
                setSubServices(data.data)
            }
        ).catch(
            err => {
                console.log(err)
            }
        )
    }
    const [staff, setStaff] = useState([])
    const getUsers = () => {
        axios.get(`/api/store/users/salon_workers/${props?.user_data?.salon_id}`).then(data => {
            setStaff(data.data)

        }).catch(err => console.log(err));
    }
    const [client, setClient] = useState([])
    const getClient = () => {
        axios.get(`/api/store/users/clients`).then(data => {
            setClient(data.data)
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        getAppointments()
        getServices();
        getUsers();
        getClient();
    }, [])

    // FILTER FUNCTION
    const [filteredData, setFilteredData] = useState();
    const filterByContent = (filterValue) => {
        if (!filterValue || filterValue == "") {
            setFilteredData(appointmentData);
        } else {
            const filteredArray = appointmentData?.filter((el) => {
                const clientName = client?.find((elm) => elm.id == el.costumer_id)?.name?.toLowerCase();
                return clientName?.includes(filterValue?.toLowerCase());
            });
            setFilteredData(filteredArray);
        }
    };
    // FILTER FUNCTION

    //PAGINATION
    const [currentPage, setCurrentPage] = useState(false);
    const startIndex = currentPage * 4;
    const endIndex = startIndex + 4;
    const numPages = Math.ceil(filteredData?.length / 4)

    const data = filteredData?.slice(startIndex, endIndex);
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    //PAGINATION


    function handleModalEdit(data) {
        setIsOpen(true)
        setModalType('edit')
        setModalData(data)
    }
    function handleModalClose() {
        setIsOpen(false)
        setIsOpenDelete(false)

    }
    const handleModalDelete = (data) => {
        setIsOpenDelete(true);
        setModalType("delete");
        setModalData(data)
    }

    const handleChangeStatus = (id, data, sid, time, date) => {
        // e.preventDefault()
        if (data == 0) {
            return null
        } else {

            axios.put(`/api/store/appointments/changeStatus/${id}`, { status_id: data, service_id: sid, appt_time: time, appt_date: date })
                .then((response) => {
                    console.log("olaLabels", response.data);
                    toast.success("Appointment updated sucessfully.")
                    getAppointments()
                    // console.log("propspropspropsprops", props)
                    props?.pendingAppointment()
                }).catch(err => {
                    toast.error("Something went wrong")
                    console.log(err)
                })
        }
    };

    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
                {isOpen === true ?
                    <AppointmentModal isOpen={isOpen} handleModalClose={handleModalClose} type={modalType} data={modalData} getAppointments={getAppointments} />
                    :
                    ""
                }
                {overSubAppointments > 0 &&
                    <Warning collapse={props.sidebar}>Plani që keni ka arritur limitin e termineve qe mund të bëhen, ka edhe {overSubAppointments} {overSubAppointments === 1 ? "termin" : "termine"}  të bëra që nuk shfaqen në kalendar. kontaktoni ekipin e Termini.Beauty në admin@termini.beauty</Warning>

                }

                {isOpenDelete === true ? (
                    <AppointmentDeleteModal
                        getAppointmentsDelete={() => getAppointments()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={modalData}
                        handleModalClose={handleModalClose}
                    />
                ) : (

                    ""
                )}

                <TableStyle collapse={props?.sidebar}>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col-1">ID</th>
                                <th scope="col-3">Client</th>
                                <th scope="col-3">Service</th>
                                <th scope="col-3">Address</th>
                                <th scope="col-3">Worker</th>
                                <th scope="col-2">Date</th>
                                <th scope="col-3">status</th>
                                <th scope="col-1">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(el => {
                                return (
                                    <>
                                        <tr>
                                            <th scope="row" className="col-1">
                                                {el?.id}
                                            </th>


                                            <td className="col-3">{el.reserver_name}</td>
                                            <td className="col-2">
                                                {subservices?.find((elm) => elm.id == el.service_id)?.name_al}
                                            </td>
                                            <td className="col-3">{el?.salon_address?.name_al}</td>
                                            <td className="col-3">{staff?.find((elm) => elm.id == el.salon_worker_id)?.name} {staff?.find((elm) => elm.id == el.salon_worker_id)?.surname}</td>
                                            <td className="col-3">{el?.date_of_appointment} | {el?.app_start}</td>
                                            {/* {
                                                el?.status === 2 ?
                                                    <td style={{ color: 'orange' }}>Canceled</td>
                                                    :
                                                    el?.status === 4 ?
                                                        <td style={{ color: 'green' }}>Approve</td>
                                                        :
                                                        el?.status === 5 ?
                                                            <td style={{ color: '#FFFF33' }}>Pending</td>
                                                            :
                                                            <td style={{ color: 'red' }}>missed</td>
                                            } */}
                                            {console.log("el", el)}

                                            <select defaultValue={el?.status} className="" onChange={(e) => {
                                                const subServiceId = Array.isArray(el?.salon_sub_services) && el?.salon_sub_services.length > 0
                                                    ? el?.salon_sub_services[0]?.id
                                                    : el?.salon_sub_service?.id;
                                                handleChangeStatus(el?.id, e.target.value, subServiceId, el?.app_start, el?.date_of_appointment)
                                            }

                                                // handleChangeStatus(el?.id, e.target.value, subservices?.find((elm) => elm.id == el.service_id)?.id, el?.app_start, el?.date_of_appointment)
                                            }>
                                                <option value={0} hidden>change status</option>
                                                <option style={{ color: 'orange' }} value={2}>Cancel</option>
                                                <option style={{ color: 'green' }} value={4}>Approved</option>
                                                <option style={{ color: '#FFFF33' }} value={5}>Pending</option>
                                                <option style={{ color: 'red' }} value={6}>Missed</option>
                                            </select>
                                            <td className="col-1">
                                                <div className="d-flex gap-3">
                                                    {el?.status === 2 || el?.status === 3
                                                        ?
                                                        <>
                                                            <i class="fa-regular fa-pen-to-square text-muted" role="button" onClick={() => toast.info("this appointment cannot be edited")} />
                                                            {/* <i class="fa-regular fa-rectangle-xmark text-muted " role="button" onClick={() => toast.info("this appointment cannot be canceled")} /> */}
                                                        </>
                                                        :
                                                        <>
                                                            <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                                                            {/* <i class="fa-regular fa-rectangle-xmark iconsForCursor " onClick={() => handleModalDelete(el)} /> */}
                                                        </>

                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}

                        </tbody>
                    </table>
                </TableStyle>
                <PaginationStyle collapse={props?.sidebar}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={numPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination justify-content-center"}
                        activeClassName={"active"}
                    // currentPage={data.length > 7 ? true : false}
                    />
                </PaginationStyle>
                {/* <AddButton onClick={() => handleModalAdd()}>
                    <i type={"button"} className="fa-solid fa-circle-plus fa-xl pt-5" style={{ color: "#FF5758" }} ></i>
                </AddButton> */}
            </>
        );
    } else
        return (
            <>
                <SearchInputHeader filterByContent={filterByContent} />
                {isOpen === true ?
                    <AppointmentModal isOpen={isOpen} handleModalClose={handleModalClose} type={modalType} data={modalData} getAppointments={getAppointments} />
                    :
                    ""
                }
                {isOpenDelete === true ? (
                    <AppointmentDeleteModal
                        getAppointmentsDelete={() => getAppointments()}
                        isOpen={isOpenDelete}
                        type={modalType}
                        dataToModal={modalData}
                        handleModalClose={handleModalClose}
                    />
                ) : (

                    ""
                )}
                <div className="container mt-4.5">
                    {data?.map(el => {
                        return (
                            <>
                                <CardStyle>
                                    <div className="mb-3">
                                        <div className="col-12 p-2">
                                            <div className="d-flex gap-3 flex-row-reverse">
                                                {/* <i class="fa-regular fa-rectangle-xmark iconsForCursor" onClick={() => handleModalDelete(el)} /> */}
                                                <i class="fa-regular fa-pen-to-square iconsForCursor" onClick={() => handleModalEdit(el)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-9">
                                                <ul class="list-group gap-3 pt-2 pb-2 ps-3">

                                                    <li className="list-group"><strong>Client:</strong> {client?.find((elm) => elm.id == el.costumer_id)?.name} {client?.find((elm) => elm.id == el.costumer_id)?.surname}</li>
                                                    <li className="list-group"><strong>Client:</strong> {client?.find((elm) => elm.id == el.costumer_id)?.name} {client?.find((elm) => elm.id == el.costumer_id)?.surname}</li>
                                                    <li className="list-group"><strong>Staff:</strong> {staff?.find((elm) => elm.id == el.salon_worker_id)?.name} {staff?.find((elm) => elm.id == el.salon_worker_id)?.surname}</li>
                                                    <li className="list-group"><strong>Service: </strong> {subservices?.find((elm) => elm.id == el.service_id)?.name_al}</li>
                                                    <li className="list-group"><strong>Address:</strong> {el?.salon_address?.name_al}</li>
                                                    <li className="list-group"><strong>Status: </strong>
                                                        <td className="col-1">
                                                            <select value={el?.status} className="" onChange={(e) => {
                                                                const subServiceId = Array.isArray(el?.salon_sub_services) && el?.salon_sub_services.length > 0
                                                                    ? el?.salon_sub_services[0]?.id
                                                                    : el?.salon_sub_service?.id;
                                                                    handleChangeStatus(el?.id, e.target.value, subServiceId, el?.app_start, el?.date_of_appointment)
                                                            }}>
                                                                <option value={0} hidden>change status</option>
                                                                <option style={{ color: 'orange' }} value={2}>Canceled</option>
                                                                <option style={{ color: 'green' }} value={4}>Approved</option>
                                                                <option style={{ color: 'yellow' }} value={5}>Pending</option>
                                                                <option style={{ color: 'red' }} value={6}>Missed</option>
                                                            </select>
                                                        </td>
                                                    </li>
                                                    <li className="list-group"><strong>Date: </strong>{el?.date_of_appointment} | {el?.app_start}</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </CardStyle>
                            </>
                        )
                    })}

                    <PaginationStyle collapse={props?.sidebar}>
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={numPages}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination justify-content-center"}
                            activeClassName={"active"}
                        // currentPage={data.length > 7 ? true : false}
                        />
                    </PaginationStyle>
                </div>
            </>
        );
};
const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        user_data: state?.data?.user_data

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable);
// export default AppointmentTable;
