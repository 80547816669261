import React from "react";
import { UsersForm, FormControl, Label, Input, ModalAddButton, FormTitle } from "../styles/MainLayout";

export const AddService = () => {
    return (
        <>
            <FormTitle className="d-flex justify-content-center mt-3">Add Service</FormTitle>
            <UsersForm>
                <FormControl>
                    <Label >Service Name</Label>
                    <Input placeholder="Service name" type="text" />
                    <Label>Time</Label>
                    <Input placeholder="Service time" type="number" />
                    <Label>Is Favourite</Label>
                    <Input placeholder="Price" type="number" />
                    <ModalAddButton type={"button"}>Add</ModalAddButton>
                </FormControl>
            </UsersForm>
        </>
    );
};

// export const EditViewService = (props) => {
//     return (
//         <>
//             <FormTitle className="d-flex justify-content-center mt-3">Edit Service</FormTitle>
            
//             <UsersForm>
//                 <FormControl>
//                     <Label >Service Name</Label>
//                     <Input disabled={props?.type === "view"} defaultValue={props?.data?.service_name} placeholder="...." type="text" />
//                     <Label>Time</Label>
//                     <Input disabled={props?.type === "view"} defaultValue={props?.data?.time} placeholder="...." type="number" />
//                     <Label>Is Favourite</Label>
//                     <Input disabled={props?.type === "view"} defaultValue={props?.data?.price} placeholder="...." type="number" />
//                     {props?.type === "view"?
//                     ""
//                     :
//                     <ModalAddButton type={"button"}>Edit</ModalAddButton>
//                     }
//                 </FormControl>
//             </UsersForm>
//         </>
//     )
// }


