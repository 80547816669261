import React, { useState } from 'react'
import { ModalDarkBackGround, ModalStyle, ModalCloseButton, } from "../../styles/MainLayout"
import { ModalStyleMobile, ModalCloseButtonMobile, } from "../../styles/MainLayoutResponsive"
import AddLocations from '../../form/salons/addLocationForm';
import ModalPortal from '../../../portal/ModalPortal';

const AddLocation = (props) => {
    console.log('modal props',props)
    return (
        <>
            <ModalPortal>
                <ModalDarkBackGround onClick={() => props.handleModalClose()} />
                <ModalStyle>
                    <div className='d-flex justify-content-end'>
                    <ModalCloseButton type={"button"} onClick={() => props.handleModalClose()}>X</ModalCloseButton>
                    </div>
                    <AddLocations getSalonAddressService={props?.getSalonAddressService} getSalon={props?.getSalon} data={props?.data} type={props?.type} handleModalClose={props?.handleModalClose}/>
                </ModalStyle>
            </ModalPortal>
        </>
    )
}

export default AddLocation
