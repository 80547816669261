import React from 'react'
import Header from './Header'
import "./LandingPage.scss"
import Photo1 from "../../assets/images/landingPage-Mockups/Fotoweb1-0.png"
import Photo2 from "../../assets/images/landingPage-Mockups/Fotoweb2-0.png"
import Photo3 from "../../assets/images/landingPage-Mockups/fotoweb3-0.png"
import Emoji from "../../assets/images/landingPage-Mockups/Emoji.svg"
import Emoji1 from "../../assets/images/landingPage-Mockups/Emoji-1.svg"
import Emoji2 from "../../assets/images/landingPage-Mockups/Emoji-2.svg"
import Emoji3 from "../../assets/images/landingPage-Mockups/emoji-3.svg"
import Emoji4 from "../../assets/images/landingPage-Mockups/emoji-4.svg"
import Emoji5 from "../../assets/images/landingPage-Mockups/emoji-5.svg"
import Footer from './Footer'
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react'

const LandingPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // console.log("location", location?.pathname?.split('/')[2])
    useEffect(() => {
        if (location?.pathname?.startsWith("/reset-password/")) {
            return navigate(`/changePassword/${location?.pathname?.split('/')[2]}`);
            // setShowResetPasswordModal(false);
            //    return null
        }
    }, [location]);

    const navigateToRegister = () => {
        navigate('/register');
    };
    return (
        <>
            <Header />
            <div className='container'>
                <div className="row">
                    <div className="col-md-6 col-12 sectionText">
                        <div className="textBox mt-5">
                            <h1 className='headingFirst'>Termini për <br /> biznes</h1>
                            <p className='paragraphFirst'>Për pronarët e bizneseve të bukurisë: <br /> për të kursyer kohën dhe zhvilluar biznesin.</p>
                        </div>
                        <div className="buttonsForRegister ">
                            <button className='registerButton' onClick={navigateToRegister}>Regjistrohu</button>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <img src={Photo1} alt="" className='photoWeb' />
                    </div>
                </div>
                <div className="headingText p-3 mt-5 mb-5">
                    <h3 className='headingThree'>Sistemi "Krejt-në-1" i zhvilluar për të menaxhuar <br /> dhe rritur biznesin tënd.</h3>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-7 textEmoji">
                    <div className="container ms-2">
                        <div className='mb-3'>
                            <div className="emojiAndText">
                                <div className="emoji">
                                    <div className='emojiCircle'>
                                        <img src={Emoji} alt="" className='emojiStyle' />
                                    </div>
                                </div>
                                <div className="textOfIcon">
                                    <h2>Menaxhoni klientët</h2>
                                    <p>Të gjitha informatat e klientit tuaj në 1 vend.</p>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="emojiAndText">
                                <div className=" emoji ">
                                    <div className='emojiCircle'>
                                        <img src={Emoji1} alt="" className='emojiStyle' />
                                    </div>
                                </div>
                                <div className="textOfIcon">
                                    <h2>Menaxhim i termineve</h2>
                                    <p>Planifiko terminet me kalendar.</p>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <div className="emojiAndText">
                                <div className=" emoji">
                                    <div className='emojiCircle'>
                                        <img src={Emoji2} alt="" className='emojiStyle' />
                                    </div>
                                </div>
                                <div className=" textOfIcon">
                                    <h2>Konfigurimi i mesazheve automatike</h2>
                                    <p>Email automatik para dhe pas terminit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <img src={Photo2} alt="" className='photoWeb' />
                </div>
            </div>

            <div className="container boxLayout mb-5">
                <div className="textBoxLayout mt-5">
                    <h2 className='textBoxLayoutHeading mt-5'>Maksimizo vizibilitetin me Termini.beauty</h2>
                    <p className='paragrafTextBoxLayout mt-3'>tregu online i shërbimeve ku shume individë <br /> janë në kërkim të shërbimeve që ju i ofroni!</p>
                </div>
                <div className="row boxImageTextLayout">
                    <div className="col-md-12 col-12 col-lg-6">
                        <img src={Photo3} alt="" className='photoWeb' />
                    </div>
                    <div className="threeBox col-md-12 col-12 col-lg-6">
                        <div className="row firstBox mb-5">
                            <div className="col-md-3 col-12 emoji">
                                <div className='emojiCircle'>
                                    <img src={Emoji3} alt="" className='emojiStyleIcon' />
                                </div>
                            </div>
                            <div className="col-md-9 col-12 textOfEmoji">
                                <p className='paragrafiText'>Klientët mund të bëjnë kërkesë për termin edhe pas përfundimit të punës! Salloni juaj është online 24/7.</p>
                            </div>
                        </div>
                        <div className="row firstBox mb-5">
                            <div className="col-md-3 col-12 emoji">
                                <div className='emojiCircle'>
                                    <img src={Emoji5} alt="" className='emojiStyleIcons' />
                                </div>
                            </div>
                            <div className="col-md-9 col-12 textOfEmoji">
                                <p className='paragrafiText'>Klientët mund të të gjejnë në bazë të adresës. </p>
                            </div>
                        </div>
                        <div className="row firstBox">
                            <div className="col-md-3 col-12 emoji">
                                <div className='emojiCircle'>
                                    <img src={Emoji4} alt="" className='emojiStyleIcon' />
                                </div>
                            </div>
                            <div className="col-md-9 col-12 textOfEmoji">
                                <p className='paragrafiText'>Reklamo shërbimet dhe ofertat pa kosto shtesë</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="registerLayout ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12 mt-4 mb-4">
                            {/* <h1 className='headingFirst'>Ofertë e <br /> lansimit!</h1>
                            <p className='mt-4 paragrafiLight'>Muaji i parë i bashkëpunimit falas!</p> */}
                        </div>
                        <div className="col-md-4 col-12 registerDiv py-5">
                            <h2 className='text-light headingThree m-0'>Regjistrohu tani:</h2>
                            <div className="inputButton mt-4">
                                <input type="text" className='inputText' placeholder='Shënoni email adresën' />
                                <div className="filloButton">
                                    <button className='filloButton'>FILLO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default LandingPage
