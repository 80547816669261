import React, { useEffect, useState, useCallback } from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AppointmentModal from "../Modal/AppointmentModal";
import { CalendarStyleResponsive } from "../styles/MainLayoutResponsive"
import { setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
import axios from "../../axios"
import SearchInputHeader from "./SearchInputHeader";
import "../../components/form/profile/profile.scss"
import { toast } from "react-toastify";
import { AddButton, Warning } from "../styles/MainLayout"


const localizer = momentLocalizer(moment);

moment.updateLocale('sq', {
    weekdays: ['E Diel', 'E Hënë', 'E Martë', 'E Mërkurë', 'E Enjte', 'E Premte', 'E Shtunë'],
    weekdaysShort: ['Diele', 'Hëne', 'Marte', 'Mërkure', 'Enjte', 'Premte', 'Shtune'],
});
const views = [Views.WEEK, Views.MONTH, Views.DAY];


const AppointmentCalendar = (props) => {
    const [allAppointments, setallAppointments] = useState([]);
    const [appointment, setAppointment] = useState([]);
    const [overSubAppointments, setOverSubAppointments] = useState([])
    // const [allAppointments, setallAppointments] = useState([{ title: "Shaban Prolluzha", start: new Date(), end: new Date().getTime() + 30 * 60000 }]);
    const [isOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState('')
    const [currentView, setCurrentView] = useState('week');
    const [clients, setClients] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [dataToModal, setDataToModal] = useState([])

    function handleAddModal() {
        if (overSubAppointments > 0) {
            toast.warning(`Plani që keni ka arritur limitin e termineve qe mund të bëhen, ka edhe ${overSubAppointments} ${overSubAppointments === 1 ? "termin" : "termine"}  të bëra që nuk shfaqen në kalendar. kontaktoni ekipin e Termini.Beauty në admin@termini.beauty`)
        } else {
            setIsOpen(true)
            setModalType("add+")
        }
    }

    const isDateDisabled = (date) => {
        const currentDate = new Date();

        return date <= currentDate;
    };
    console.log("pamja", views)
    function handleSelectSlot(slotInfo) {
        if (isDateDisabled(slotInfo?.start) && props?.user_data?.role != 4) {
            toast.info('Past dates are not allowed!');
            return false;
        }
        else if (overSubAppointments > 0) {
            toast.warning(`Plani që keni ka arritur limitin e termineve qe mund të bëhen, ka edhe ${overSubAppointments} ${overSubAppointments === 1 ? "termin" : "termine"}  të bëra që nuk shfaqen në kalendar. kontaktoni ekipin e Termini.Beauty në admin@termini.beauty`)
        }
        else if (props?.user_data?.role == 4) {
            return false
        }
        else if (currentView == 'month') {
            return false
        }
        else {
            setAppointment({
                ...appointment,
                start: slotInfo?.start,
                end: new Date(new Date(appointment.start).getTime() + 30 * 60000),
            });
            setIsOpen(true);
            setModalType("add")
        }
    }
    function handleModalClose() {
        setIsOpen(false);
    }

    const handleNavigate = (newDate) => {
        getAppointments(moment(newDate));
    };

    const handleViewChange = (view) => {
        setCurrentView(view);
    };
    const handleSelectEvent = (data) => {
        console.log('prej editi', data)
        setDataToModal(data)
        setIsOpen(true)
        setModalType('edit')
    };
    // const handleSelectEvent = useCallback(
    //     (event) => window.alert(event.title),
    //     []
    //   )
    const CustomToolbar = ({ onNavigate, onView }) => (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" onClick={() => onNavigate('PREV')}>&lt;</button>
                <button type="button" onClick={() => onNavigate('TODAY')}>Sot</button>
                <button type="button" onClick={() => onNavigate('NEXT')}>&gt;</button>
            </span>
            {console.log("Views",)}
            <span className="rbc-toolbar-label">
                <button type="button" className={currentView === 'day' && "background_calendar_button"} onClick={() => onView(Views.DAY)}>Ditët</button>
                <button type="button" className={currentView === 'week' && "background_calendar_button"} onClick={() => onView(Views.WEEK)}>Javët</button>
                <button type="button" className={currentView === 'month' && "background_calendar_button"} onClick={() => onView(Views.MONTH)}>Muajt</button>
            </span>
        </div>
    );


    const getAppointments = async (date) => {
        const theDate = moment(date)
        if (props?.user_data?.role == 4) {

            await axios.get(`api/store/appointments/singleWorker/${props?.user_data?.user_id}`)
                .then((response) => {
                    console.log("response", response)
                    const appointments = response.data?.data.map((appointment) => {
                        const title = `${appointment.user.name} - Address : ${appointment.salon_address.street_address_al}`;
                        const startDate = moment(appointment.date_of_appointment).toDate();
                        const startTime = moment(appointment.app_start, "HH:mm:ss").toDate();
                        const endTime = moment(appointment.app_end, "HH:mm:ss").toDate();
                        const start = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            startTime.getHours(),
                            startTime.getMinutes(),
                            startTime.getSeconds()
                        );
                        const end = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            endTime.getHours(),
                            endTime.getMinutes(),
                            endTime.getSeconds()
                        );
                        return {
                            ...appointment,
                            start,
                            end,
                            title,
                            key: appointment.id
                        };
                    });

                    setallAppointments(appointments);
                    const overappointments = response.data.over_limit_appt
                    setOverSubAppointments(overappointments)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            axios.get(`api/store/appointments/all/${props?.user_data?.salon_id}/${theDate.toISOString().split("T")[0]}`)
                .then((response) => {
                    console.log('response', response)

                    const appointments = response?.data?.res?.map((appointment) => {
                        const title = `${appointment.user.name} ${appointment.user.surname} `;
                        const startDate = moment(appointment.date_of_appointment).toDate();
                        const startTime = moment(appointment.app_start, "HH:mm:ss").toDate();
                        const endTime = moment(appointment.app_end, "HH:mm:ss").toDate();
                        const start = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            startTime.getHours(),
                            startTime.getMinutes(),
                            startTime.getSeconds()
                        );
                        const end = new Date(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            endTime.getHours(),
                            endTime.getMinutes(),
                            endTime.getSeconds()
                        );

                        return {
                            ...appointment,
                            start,
                            title,
                            end,
                            key: appointment.id
                        };
                    });
                    setallAppointments(appointments);
                    const overappointments = response.data.over_limit_appt
                    setOverSubAppointments(overappointments)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };



    const dayStart = new Date();
    dayStart.setHours(6, 0, 0);
    const dayEnd = new Date();
    dayEnd.setHours(23, 30, 0);

    useEffect(() => {
        getAppointments();
        // getClients()
    }, [])
    console.log('appointments', overSubAppointments)
    const eventContent = ({ event }) => (
        //   console.log('event data 123', event)
        <div style={{ fontSize: '9px', height: "auto", display: 'flex', gap: '2px' }}>
            <strong>
                Address: {event?.salon_address?.street_address_al}
            </strong>
            <br />
            <strong>
                Customer: {event.reserver_name}
            </strong>
            <br />
            <strong>
                Worker: {event?.user?.name}
            </strong>
        </div>
    );

    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });
    if (viewportSize > 800) {
        return (
            <>
                <SearchInputHeader view={props?.calendarView} />
                {
                    isOpen == true ?
                        <AppointmentModal isOpen={isOpen} data={dataToModal} handleModalClose={handleModalClose} appointment={appointment} type={modalType} getAppointments={getAppointments} />
                        :
                        ""
                }



                {/* <CalendarStyle collapse={props?.sidebar}> */}
                {/* <div className="card"> */}
                {overSubAppointments > 0 &&
                    <Warning className="text-center w-100" collapse={props.sidebar}>Plani që keni ka arritur limitin e termineve qe mund të bëhen, ka edhe {overSubAppointments} {overSubAppointments === 1 ? "termin" : "termine"}  të bëra që nuk shfaqen në kalendar. kontaktoni ekipin e Termini.Beauty në admin@termini.beauty</Warning>
                }
                <Calendar
                    // onSelectSlot={handleSelecting}
                    onNavigate={handleNavigate}
                    onView={handleViewChange}
                    defaultView={Views.WEEK}
                    localizer={localizer}
                    events={allAppointments}
                    className="test"
                    startAccessor="start"
                    endAccessor="end"
                    style={props?.sidebar === "collapsed" ? { position: 'fixed', right: '10px', left: '90px', backgroundColor: 'white', top: '100px', height: "85%" } : { position: 'fixed', right: '10px', left: '260px', backgroundColor: 'white', top: '100px', height: "85%" }}
                    selectable={true}
                    step={30}
                    views={views}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    timeslots={1}
                    min={dayStart}
                    max={dayEnd}
                    components={{
                        toolbar: CustomToolbar,
                        event: eventContent
                    }}
                    eventPropGetter={(event) => {
                        // const backgroundColor = event?.title ? "#f09fb4" : "#f09fb4";.
                        const foundAppointment = allAppointments.find((el) => el.id === event.id);
                        let backgroundColor;
                        if (foundAppointment) {
                            backgroundColor = foundAppointment.status === 1 ? "green"
                                : foundAppointment.status === 2 ? "red"
                                    : foundAppointment.status === 3 ? "lightblue"
                                        : foundAppointment.status === 4 ? "orange"
                                            : foundAppointment.status === 5 ? "gray"
                                                : foundAppointment.status === 7 ? "black"
                                                    : "#f09fb4"
                        } else {
                            backgroundColor = "#f09fb4"; // Default color if the event is not found in allAppointments
                        }
                        return {

                            style: {
                                backgroundColor,
                                borderRadius: "0px",
                                opacity: 0.8,
                                color: "white",
                                border: "1px solid",
                                borderColor: "red",
                                display: "block",
                                margin: "0px 3px",

                            }
                        }
                    }}

                />
                <AddButton>
                    <i type={"button"} className="fa-solid fa-circle-plus fa-xl" style={{ color: "#FF5758" }} onClick={() => { handleAddModal() }}></i>
                </AddButton>

                {/* </div> */}
                {/* </CalendarStyle> */}
            </>
        );
    }
    else {
        return (
            <>
                {/* {overSubAppointments > 0 ?
                    <Warning collapse={props.sidebar}>Plani që keni ka arritur limitin e termineve qe mund të bëhen, ka edhe {overSubAppointments} termine të bëra që nuk shfaqen në kalendar. Kontaktoni Administratorin : rina@perigee.biz</Warning>
                    :
                    ""
                } */}
                {
                    isOpen == true ?
                        <AppointmentModal isOpen={isOpen} data={dataToModal} handleModalClose={handleModalClose} appointment={appointment} type={modalType} getAppointments={getAppointments} />
                        :
                        ""
                }
                <SearchInputHeader view={props?.calendarView} />
                <Calendar
                    onNavigate={handleNavigate}
                    onView={handleViewChange}
                    localizer={localizer}
                    events={allAppointments}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600, width: "100%", backgroundColor: "white", padding: '15px' }}
                    selectable={true}
                    step={30}
                    timeslots={1}
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    views={views}
                    min={dayStart}
                    defaultView={Views.DAY}
                    max={dayEnd}
                    components={{
                        toolbar: CustomToolbar,
                        event: eventContent
                    }}
                    eventPropGetter={(event) => {
                        // const backgroundColor = event?.title ? "#f09fb4" : "#f09fb4";.
                        const foundAppointment = allAppointments.find((el) => el.id === event.id);
                        let backgroundColor;
                        if (foundAppointment) {
                            backgroundColor = foundAppointment.status === 1 ? "green"
                                : foundAppointment.status === 2 ? "red"
                                    : foundAppointment.status === 3 ? "lightblue"
                                        : foundAppointment.status === 4 ? "orange"
                                            : foundAppointment.status === 5 ? "gray"
                                                : foundAppointment.status === 7 ? "black"
                                                    : "#f09fb4"
                        } else {
                            backgroundColor = "#f09fb4"; // Default color if the event is not found in allAppointments
                        }
                        return {

                            style: {
                                backgroundColor,
                                borderRadius: "0px",
                                opacity: 0.8,
                                color: "white",
                                border: "1px solid",
                                borderColor: "red",
                                display: "block",
                                margin: "0px 3px",

                            }
                        }
                    }}
                />
                <AddButton>
                    <i type={"button"} className="fa-solid fa-circle-plus fa-xl" style={{ color: "#FF5758" }} onClick={() => handleAddModal()}></i>
                </AddButton>
            </>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        selected_lang: state.data.selected_lang,
        logged_in: state.data.logged_in,
        sidebar: state?.data?.sidebar,
        user_data: state?.data?.user_data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSideBar: (data) => dispatch(setSideBar(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCalendar);
// export default AppointmentCalendar;
